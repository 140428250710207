<template>
  <div class="fixed inset-0 z-[777] backdrop-blur-sm" @click="hideAllMenus">
    <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity" @click="close"></div>
    
    <div class="flex h-full md:py-[5vh] z-[888] min-w-[300px]">
      <div class="relative w-full max-w-md mx-auto flex flex-col h-full bg-slate-800 border border-slate-700 shadow-xl rounded-lg">
        
        <!-- Fixed Header -->
        <div class="bg-slate-900 p-3 py-2 flex justify-between items-center border-b border-slate-700 rounded-t-lg">
          <h3 class="text-lg font-medium">Wallet</h3>
          <button @click="close" class="text-slate-400 hover:text-slate-200">
            <img src="@/assets/set/close.svg" class="w-5 h-5" />
          </button>
        </div>

        <div v-if="noWallets">
            <WalletNoWallet />
        </div>
        <div v-else class="flex flex-col flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-slate-600 scrollbar-track-slate-800 hover:scrollbar-thumb-slate-500">

            <div class="bg-slate-900 p-3 flex justify-between items-center border-b border-slate-700 rounded-t-lg">
                <div class="w-8 h-8 bg-black p-1 rounded-lg">
                    <img src="@/assets/net/solana.png" class="w-6 h-6">
                </div>
                <div class="min-w-[50%] cursor-pointer" @click="toggleView('list')">
                    <div class="bg-slate-700 w-full rounded-lg p-2 bg-opacity-45 flex flex-row gap-2 items-center justify-between">
                        <img src="@/assets/set/wallet.svg" class="w-5 h-5">
                        <span class="font-mono c1 group">
                            {{ selectedWallet ? formatWallet(selectedWallet.wallet_address, 4) : '---' }}
                            <span class="ml-1 group-hover:inline-block hidden" v-if="selectedWallet">
                                <img src="@/assets/set/copy.svg" class="w-4 h-4 cursor-pointer" @click.stop="copyToClipboard(selectedWallet?.wallet_address)">
                            </span>
                        </span>
                        <div class="text-sm text-slate-400">
                            <img src="@/assets/set/down-g.svg" 
                                class="w-4 h-4 mr-1 transition-transform duration-300" 
                                :class="{'rotate-180': view === 'list'}">
                        </div>
                    </div>

                </div>
                <div class="relative" @click.stop>
                    <button class="w-6 h-10 p-1 rounded-lg cursor-pointer" @click="toggleMenu">
                        <img src="@/assets/set/dots-v.svg" class="h-5 mt-0 mx-auto">
                    </button>

                    <!-- Dropdown Menu -->
                    <div v-if="showMenu" 
                        class="absolute right-0 top-12 w-48 bg-slate-800 rounded-lg border border-slate-700 shadow-lg z-50"
                        @click.stop>
                        <div class="py-1">
                            <!-- Notifications -->
                        
                            <!-- <div @click="toggleView('export')" class="flex items-center px-4 py-2 hover:bg-slate-700 cursor-pointer border-t border-slate-700">
                                <img src="@/assets/set/user.svg" class="w-4 h-4 mr-3">
                                <span class="text-sm">Bridge</span>
                                <span class="ml-auto text-xs bg-blue-500 px-2 py-0.5 rounded-full">New!</span>
                            </div> -->
                        
                            <!-- <div class="flex items-center px-4 py-2 hover:bg-slate-700 cursor-pointer">
                                <img src="@/assets/set/user.svg" class="w-4 h-4 mr-3">
                                <span class="text-sm">Exp details</span>
                            </div>
                            
                        -->
                            <a :href="`https://solscan.io/account/${selectedWallet?.wallet_address}`" target="_blank" class="flex items-center px-4 py-2 hover:bg-slate-700 cursor-pointer">
                                <img src="@/assets/set/find.svg" class="w-4 h-4 mr-3">
                                <span class="text-sm">View on explorer</span>
                                <!-- <span class="ml-auto text-xs text-slate-400">etherscan.io</span> -->
                            </a>
                            <div @click="toggleView('export')" class="flex items-center px-4 py-2 hover:bg-slate-700 cursor-pointer border-t border-slate-700">
                                <img src="@/assets/set/exit.svg" class="w-4 h-4 mr-3">
                                <span class="text-sm">Export</span>
                                <!-- <span class="ml-auto text-xs bg-blue-500 px-2 py-0.5 rounded-full">New!</span> -->
                            </div>
                            
                            
                            <!-- <div class="flex items-center px-4 py-2 hover:bg-slate-700 cursor-pointer">
                                <img src="@/assets/set/user.svg" class="w-4 h-4 mr-3">
                                <span class="text-sm">All Permissions</span>
                            </div>
                            
                        
                            <div class="flex items-center px-4 py-2 hover:bg-slate-700 cursor-pointer border-t border-slate-700">
                                <img src="@/assets/set/user.svg" class="w-4 h-4 mr-3">
                                <span class="text-sm">Expand view</span>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>

            <WalletList v-if="view==='list'" @toggleView="toggleView" @chooseWallet="chooseWallet" @hideWallet="close" />

            <WalletHome v-if="view==='home'" @toggleView="toggleView" @hideWallet="close" />
            <WalletSend v-if="view==='send'" @toggleView="toggleView" @hideWallet="close" />
            <WalletDeposit v-if="view==='deposit'" @toggleView="toggleView" @hideWallet="close" />
            <WalletExport v-if="view==='export'" @toggleView="toggleView" @hideWallet="close" />

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import WalletHome from './Wallet/Home.vue';
import WalletSend from './Wallet/Send.vue';
import WalletDeposit from './Wallet/Deposit.vue';
import WalletList from './Wallet/List.vue';
import WalletExport from './Wallet/Export.vue';
import WalletNoWallet from './Wallet/NoWallet.vue';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {

    //   props: {
    //     walletId: String,
    //     // initialView: {
    //     //   type: String,
    //     //   default: 'home'
    //     // }
    //   },
  components: {
        WalletHome,
        WalletSend,
        WalletList,
        WalletDeposit,
        WalletExport,
        WalletNoWallet,
  },
  data() {
    return {
        view: 'home',
        wallets: {},
        viewList: false,
        viewSend: false,
        viewTab: 0,
        showMenu: false,
        isEditing: false,
        editedName: ''
    }
  },
  computed: {
    ...mapState('playwallet', ['playWallets', 'selectedWallet', 'isWalletsLoading', 'noWallets']),
    ...mapGetters('modals', ['openWalletView']),
  },
  created() {
    if (this.openWalletView !== null) {
      this.view = this.openWalletView;
    }
  },
  methods: {
    ...mapActions('playwallet', ['fetchWallets']),

    close() {
        this.$emit('hideWallet')
    },

    toggleView(view) {
      if(view===this.view) {
        this.view = 'home';
      } else {
        this.view = view;
      }
      this.hideAllMenus();
    },
   
    hideAllMenus() {
      this.showMenu = false;
    },
    
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
}
</script> 

<style scoped>
/* Add new animation styles */
.rotate-180 {
  transform: rotate(180deg);
}
</style> 