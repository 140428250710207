<template>
    
    <div class="flex-1 overflow-y-auto p-3 scrollbar-hide">
 
        <div v-if="showNewWallet" class="bg-slate-900 rounded-xl p-4 flex flex-col gap-1 mb-3">
            <img src="@/assets/set/check.svg" class="w-8 h-8 inline-block mx-auto mt-2">
            <div class="text-center text-lg c3 mt-2 font-bold">
                Your new Play Wallet is ready!
            </div>
            <div class="text-center">
                Add some SOL to begin using the platform.
            </div>
            <!-- <div class="mb-2 text-slate-300 text-sm"><img src="@/assets/set/warn2.svg" class="w-4 h-4 inline-block"> Don't forget to export private keys and store them securely.</div> -->
        </div>

        <h3 class="c2">Deposit SOL</h3>

        <div class="bg-slate-900 rounded-xl p-4 flex flex-col gap-3 mt-3">
            <div class="flex flex-col gap-2">
                <h5 class="c3 text-center">Send SOL to</h5>
           

                <!-- QR Code section -->
                <div class="flex justify-center py-2 mb-1">
                    <qrcode-vue
                        :value="selectedWallet?.wallet_address || ''"
                        :size="200"
                        level="H"
                        render-as="svg"
                        :background="'#0f172a'"
                        :foreground="'#f8fafc'"
                        class="rounded-xl"
                    />
                </div>
                <div class="text-slate-200 uppercase c3 text-center">
                    Your Solana Wallet Address
                </div>
                <div class="bg-slate-800 rounded-lg p-2 px-3 font-mono text-sm c1 flex items-center relative">
                    <div class="overflow-hidden relative flex-1 pr-2">
                        <div class="truncate" ref="addressText">{{ selectedWallet?.wallet_address }}</div>
                        <div v-if="isTextTruncated" class="absolute right-0 top-0 h-full w-12 bg-gradient-to-r from-transparent via-slate-800 to-slate-800"></div>
                    </div>
                    <button @click="copyAddress()" class="flex-shrink-0">
                        <img src="@/assets/set/copy-g.svg" class="w-4 h-4">
                    </button>
                </div>

            </div>
    
            <div class="flex flex-col gap-2 bg-slate-950 rounded-lg p-4">
                <h5 >Deposit From Wallet</h5>
                <!-- <input type="text" v-model="depositAmount" class="w-full text"> -->
                    <div v-if="isConnected">
                        <div class="relative ">
                            <input
                                type="text"
                                class="w-full pr-10 text focus:outline-none text-lg"
                                placeholder="0.0"
                                v-model="depositAmount"
                                @input="updateTotalSol"
                                style="padding-left:38px !important"
                                />
                                <!-- :style="coinData?.coin_image ? {'padding-left': '38px'} : {}" -->
                            <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">SOL</span>
                            <img src="@/assets/set/sol.svg" class="absolute inset-y-0 left-0 w-5 h-5 ml-2 my-auto">
                        </div>
                        <div class="flex flex-warp gap-2 " v-if="walletType === 'phantom' || walletType === 'solflare' ">
                            <button @click="sendFromPhantom" class="b3 be" v-if="walletType === 'phantom'" >Send from Phantom</button>
                            <button @click="sendFromSolflare" class="b3 be" v-if="walletType === 'solflare'" >from Solflare</button>
                        </div>
                        <div v-else>
                            <button @click="connectPhantomWallet()" class="b3 be" v-if="isPhantomInstalled">Connect Phantom</button>
                            <button @click="connectSolflareWallet()" class="b3 be" v-if="isSolflareInstalled">Connect Solflare</button>
                        </div>
                    </div>
                    <div v-else>
                        <div class="flex flex-col gap-2">
                            <!-- v-if="isPhantomInstalled || isSolflareInstalled" -->
                            <!-- v-if="isPhantomInstalled" -->
                            <button @click="connectPhantomWallet()" class="b3 be" >Connect Phantom</button>
                            <!-- v-if="isSolflareInstalled" -->
                            <button @click="connectSolflareWallet()" class="b3 be" >Connect Solflare</button>
                        </div>
                        <!-- <button v-else @click="connectWalletClick()" class="b3 be">Connect Wallet</button> -->
                    </div>
            </div>

            <div class="mb-2 text-slate-300 flex flex-row gap-2 items-start">
                <img src="@/assets/set/info.svg" class="w-5 h-5 inline-block mt-1"> 
                <div class="text-base">Transfer SOL via Solana network from any Solana wallet or send from exchange. 
                    Press Done when finished to check your balance.
                </div>
            </div>   
        </div>

        <!-- <div v-if="showNewWallet" class="bg-slate-900 rounded-xl p-4 flex flex-col gap-3 mt-3">
            <div class="mb-2 text-slate-300 text-sm"><img src="@/assets/set/warn2.svg" class="w-4 h-4 inline-block"> Don't forget to export private keys and store them securely.</div>
        </div> -->
        <!-- <button class="b3 ba ml-auto" @click="toggleView('home')">Done</button> -->

    </div>

    <!-- Fixed Footer -->
    <div class="bg-slate-900 px-4 py-3 flex justify-between border-t border-slate-700 rounded-b-lg">

        <button class="b3 ba ml-auto" @click="toggleView('home')">Done</button>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import QrcodeVue from 'qrcode.vue';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';

export default {
    components: {
        QrcodeVue
    },
    data() {
        return {
            isTextTruncated: false,
            showNewWallet: false,
            depositAmount: 0.1
        }
    },
    computed: {
        ...mapState('playwallet', ['selectedWallet']),
        ...mapGetters('modals', ['openWalletNewWallet']),
        ...mapState('wallets', ['isConnected', 'walletType']),
        ...mapState(['isMobile']),
        ...mapState('phantom', {isPhantomInstalled: 'isInstalled'}),
        ...mapState('solflare', {isSolflareInstalled: 'isInstalled'}),
        hasWalletsInstalled() {
            return this.isPhantomInstalled || this.isSolflareInstalled;
        }
    },
    methods: {
        ...mapActions('modals', ['resetWalletNew', 'showConnectWalletModal']),
        ...mapActions('wallets', ['sendTX', 'connectWallet']),
        ...mapActions(['showNotification']),
        toggleView(view) {
            this.$emit('toggleView', view);
        },
        copyAddress() {
            navigator.clipboard.writeText(this.selectedWallet?.wallet_address);
        },
        checkTextTruncation() {
            const element = this.$refs.addressText;
            if (element) {
                this.isTextTruncated = element.scrollWidth > element.clientWidth;
            }
        },

        connectWalletClick() {
            // Use the modal approach to show the modal with multiple wallet options
            this.showConnectWalletModal();
        },

        connectPhantomWallet() {
            try {
                this.connectWallet({ 
                    walletType: 'phantom', 
                    // justAdd: true, 
                    justConnect: true,
                    deeplink: this.isMobile 
                }).then(() => {
                    this.showNotification({
                        message: 'Phantom wallet connected successfully',
                        type: 'success'
                    });
                }).catch(error => {
                    console.error('Phantom connection error:', error);
                    this.showNotification({
                        message: `Failed to connect Phantom: ${error.message || 'Unknown error'}`,
                        type: 'error'
                    });
                });
            } catch (error) {
                console.error('Phantom connection error:', error);
                this.showNotification({
                    message: `${error.message || 'Unknown error'}`,
                    type: 'warning'
                });
            }
        },

        connectSolflareWallet() {
            try {
                this.connectWallet({ 
                    walletType: 'solflare', 
                    // justAdd: true, 
                    justConnect: true,
                    deeplink: this.isMobile 
                }).then(() => {
                    this.showNotification({
                        message: 'Solflare wallet connected successfully',
                        type: 'success'
                    });
                }).catch(error => {
                    console.error('Solflare connection error:', error);
                    this.showNotification({
                        message: `Failed to connect Solflare: ${error.message || 'Unknown error'}`,
                        type: 'error'
                    });
                });
            } catch (error) {
                console.error('Solflare connection error:', error);
                this.showNotification({
                    message: `${error.message || 'Unknown error'}`,
                    type: 'warning'
                });
            }
        },

        updateTotalSol() {
            // Ensure numeric input only
            this.depositAmount = this.depositAmount.toString().replace(/[^0-9.]/g, '');
            
            // Prevent multiple decimal points
            const decimalCount = (this.depositAmount.match(/\./g) || []).length;
            if (decimalCount > 1) {
                this.depositAmount = this.depositAmount.slice(0, this.depositAmount.lastIndexOf('.'));
            }
            
            // Limit to 9 decimal places (SOL has 9 decimals)
            const parts = this.depositAmount.split('.');
            if (parts.length > 1 && parts[1].length > 9) {
                this.depositAmount = `${parts[0]}.${parts[1].slice(0, 9)}`;
            }
        },

        ...mapActions('wallets', ['sendTX']), // 'connectWallet', 'disconnectWallet', 

        async sendFromPhantom() {
            if (!this.depositAmount || this.depositAmount <= 0) {
                this.$store.dispatch('showNotification', {
                    message: 'Please enter a valid amount',
                    type: 'error'
                });
                return;
            }
            
            try {
                // Check if Phantom is connected, if not - connect it
                // not here? brought it to the wallets.js ...
                // if (this.walletType !== 'phantom' || !this.isConnected) {
                //     await this.connectWallet({ walletType: 'phantom', mode: 'pay', justAdd: true });
                // }
                // - or -
                // if (this.walletType !== 'phantom' || !this.isConnected) {
                //     await this.connectWallet({ 
                //         walletType: 'phantom', 
                //         justAdd: true, 
                //         deeplink: this.isMobile 
                //     });
                // }

                // Convert SOL to lamports (1 SOL = 1,000,000,000 lamports)
                const lamports = Math.floor(this.depositAmount * LAMPORTS_PER_SOL);

                // Send transaction
                // const signature = await this.$store.dispatch('phantom/signTransaction', {
                //     address: this.selectedWallet?.wallet_address,
                //     amount: lamports,
                    
                // });
                await this.sendTX({
                    walletType: 'phantom',
                    address: this.selectedWallet?.wallet_address,
                    amount: lamports,
                    deeplink: this.isMobile
                });
                
                this.$store.dispatch('showNotification', {
                    message: 'Transaction sent successfully. Signature: ' + signature.slice(0, 8) + '...',
                    type: 'success'
                });

            } catch (error) {
                console.error('Transaction error:', error);
                this.$store.dispatch('showNotification', {
                    message: `Transaction failed: ${error.message}`,
                    type: 'error'
                });
            }
        },

        async sendFromSolflare() {
            if (!this.depositAmount || this.depositAmount <= 0) {
                this.$store.dispatch('showNotification', {
                    message: 'Please enter a valid amount',
                    type: 'error'
                });
                return;
            }

            try {
                // Check if Solflare is connected, if not - connect it
                if (this.walletType !== 'solflare' || !this.isConnected) {
                    await this.connectWallet({ walletType: 'solflare', justAdd: true });
                    //  or await this.connectWallet({ 
                    //     walletType: 'solflare', 
                    //     justAdd: true,
                    //     deeplink: this.isMobile
                    // });
                }

                const lamports = this.depositAmount * 1000000000; // Convert SOL to lamports
                await this.sendTX({
                    walletType: 'solflare',
                    address: this.selectedWallet?.wallet_address,
                    amount: lamports
               
                    // deeplink: this.isMobile
                });

                this.$store.dispatch('showNotification', {
                    message: 'Transaction sent successfully',
                    type: 'success'
                });
            } catch (error) {
                console.error('Transaction error:', error);
                this.$store.dispatch('showNotification', {
                    message: `Transaction failed: ${error.message}`,
                    type: 'error'
                });
            }
        },
    },
    mounted() {

        this.checkTextTruncation();
        window.addEventListener('resize', this.checkTextTruncation);
        // alert (' deposit opened with '+ this.openWalletNewWallet);
        // Reset newWallet flag after showing the message
        if (this.openWalletNewWallet) {
            this.showNewWallet = true;
            // alert (' showNewWallet true and resetWalletNew ! ');
            this.resetWalletNew();
        }
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.checkTextTruncation);
    },
    watch: {
        'selectedWallet.wallet_address'() {
            this.$nextTick(this.checkTextTruncation);
        }
    }
}
</script>