<template>

    <div class=" z-[500] bg-[#091622] border-b border-slate-700 bg-opacity-65 backdrop-blur-sm
     fixed top-0 right-0 left-0 md:left-14 
     md:px-3 px-2 flex flex-row gap-2 items-center
     md:h-16 h-12 
    " :class="{'mt-8': showLiner}">

        <div v-if="!isAuth">
            <button @click="clickLogo()" class="flex flex-row gap-3 w-[200px] items-center ml-2">
                <img src="@/assets/air.svg" class="w-7 h-7 mr-1 md:hidden">
                <img src="@/assets/logo_text.png" class="h-[20px] mt-1">
            </button>
        </div>
        <div v-else class="shrink-0 w-8 pl-2 md:hidden ">
            <button @click="clickLogo()" class="mb-4"><img src="@/assets/air.svg" class="w-5 mt-3"></button>
            <!-- <router-link to="/" class="mb-4"><img src="@/assets/air.svg" class="w-5 mt-3"></router-link> -->
        </div>
        
        <div v-if="isAuth" class="w-[140px] md:w-[210px] min-w-0 flex-shrink max-md:flex-grow">
            <div class="group flex flex-row px-2 py-1 border border-slate-700 hover:border-[#89D2C3] rounded-3xl"
                 :class="{ 
                     'search-focus-animation': isSearchFocused,
                     'search-focused': isSearchFocused 
                 }">
                <div class="shrink-0">
                    <img src="@/assets/set/find.svg" class="w-5 h-5 inline-block my-auto opacity-50 group-hover:opacity-55" />
                </div>
                <div class="flex-grow min-w-0">
                    <input 
                        ref="searchInput"
                        v-model="search" 
                        @input="handleSearch" 
                        @focus="isSearchFocused = true"
                        @blur="isSearchFocused = false"
                        @keyup.esc="finishSearch"
                        placeholder="Search"
                        class="w-full border-0 bg-transparent text-lg search focus:outline-none truncate"
                    />
                </div>
                <div class="flex items-center gap-2 shrink-0">
                    <div v-show="closeSearch" @click="finishSearch()" class="shrink-0 cursor-pointer">
                        <img src="@/assets/set/close.svg" class="w-5 h-5 inline-block my-auto opacity-25 group-hover:opacity-55" />
                    </div>
                    <div class="text-xs text-slate-500 border border-slate-600 rounded px-2 max-md:hidden shrink-0">/</div>
                </div>
            </div>
        </div>
        
        <div class="flex-grow"></div>

        <div v-if="!isAuth">
            <button @click="showAuth()" class="ba b3">
                <img src="@/assets/set/user.svg" class="w-5 h-5">
                Login
            </button>
                   <!-- <router-link :to="{ name: 'launch' }" class="ba b3">
                <img src="@/assets/set/wallet.svg" class="w-5 h-5">
                Connect Wallet
            </router-link>-->
        </div>

        <div v-else class="relative flex flex-row gap-1 md:gap-2 items-center">

            <!-- Notification / Alerts -- report on automated actions perfromed and praces changed -->
            <div class="text-sm shrink-0 relative">
                <div @click.stop="toggleNotifList" class="flex flex-row gap-1 w-fit px-1.5 md:px-2 py-1 rounded-lg border border-[#405365] cursor-pointer">
                    <div v-if="isLoadingNotifs" class="spinner mx-auto"></div>
                    <img v-else src="@/assets/set/bell.svg" class="w-4 h-4">
                </div>
                
                <div v-if="showNotifList" 
                    class="absolute right-0 top-full mt-2 w-64 bg-slate-800 rounded-lg border border-slate-700 shadow-lg z-50"
                    style="transform-origin: top right;">
                    <div class="max-h-[300px] overflow-y-auto py-2">
                        <h5 class=" text-slate-400 uppercase text-sm pb-3 pl-4">Notifications</h5>
                        <div v-if="isLoadingNotifs" class="p-4 text-center">
                            <div class="spinner-m mx-auto"></div>
                        </div>
                        <div v-else-if="recentNotifications?.length === 0" class="p-4 text-center text-slate-400">
                            <!-- No wallets found. -->
                            <img src="@/assets/set/archive.svg" class="w-12 md:w-8 h-12 md:h-8 mb-3 mx-auto">
                                <div class="text-center uppercase text-slate-200 mb-5 text-lg">No notifications</div>
                                <div class="text-center">You will get notified on performed trading orders and launched coins.</div>
                                <!-- <button @click="showWallet()" class="b4 ba mx-auto">Create Wallet</button> -->
                                <!-- <div class="mt-3 mx-auto">
                                    <router-link :to="{ name: 'docs_market' }" class="linkb mt-1 mx-auto">Learn More</router-link>
                                </div> -->
                        </div>
                        <div v-else class="border-t border-slate-700">
                            <div v-for="notif in recentNotifications" 
                                    :key="notif.notif_id"
                                    class="flex items-center px-4 py-2 hover:bg-slate-700 border-b border-slate-700"
                                   >
                                   <!-- @click="selectNotif(notif)" cursor-pointer -->
                                <div class="flex-1">
                                    <div class="text-sm font-medium">
                                        {{ getNotifTypeText(notif.notif_type) }}
                                    </div>
                                    <div class="text-xs text-slate-200">{{ notif.notif_text }}</div>
                                </div>
                                <div v-if="notif.notif_value && parseFloat(notif.notif_value) > 0" 
                                     class="ml-auto flex-grow font-mono text-xs items-start c2 text-right">
                                    {{ formatValue(notif.notif_value) }} SOL
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pb-3 pl-3 flex flex-row gap-2 mb-2">
                        <router-link :to="{ name: 'notifications' }" @click="showNotifList = false" class="b5 bb mx-auto">All Notifications</router-link>
                    </div>
                </div>
            </div>

            <!-- AI Agents Suggestions -- with actions to be performed -->
            <!-- <div class="text-sm shrink-0">
                <router-link :to="{ name: 'notifications' }" class="flex flex-row gap-1 w-fit px-1.5 md:px-2 py-1 rounded-lg border border-[#405365]">
                    <img src="@/assets/set/ai.svg" class="w-4 h-4">
                </router-link>
            </div> -->

            <div class="relative shrink-0">
                <div @click="showWallet()" 
                     class="bg-slate-800 rounded-lg px-1.5 md:px-2 py-1 flex flex-row gap-1 md:gap-2 items-center cursor-pointer">
                    <img src="@/assets/set/wallet.svg" class="w-4 md:w-5 h-4 md:h-5">
                    <div v-if="noWallets" class="text-slate-400">
                        No Wallets
                    </div>
                    <!-- <div v-if="noWallets" class="c1">
                         <span class="flex flex-row gap-1 items-center">
                            <img src="@/assets/set/plus-g.svg" class="w-3 md:w-4 h-3 md:h-4 mr-1 mb-1">
                            Play Wallet
                        </span>
                    </div> -->
                    <span class="c2 truncate max-w-[80px] md:max-w-none" v-if="selectedWallet" v-html="formatWallet(selectedWallet.wallet_address, 4, true)"></span>
                    <button @click.stop="toggleWalletList" class="shrink-0">
                        <img src="@/assets/set/down-c2.svg" 
                             class="w-3 h-3 transition-transform duration-300"
                             :class="{'rotate-180': showWalletList}">
                    </button>
                </div>

                <div v-if="showWalletList" 
                     class="absolute right-0 top-full mt-2 w-64 bg-slate-800 rounded-lg border border-slate-700 shadow-lg z-50"
                     style="transform-origin: top right;">
                    <div class="max-h-[300px] overflow-y-auto py-2">
                        <div v-if="isLoading" class="p-4 text-center">
                            <div class="spinner-m mx-auto"></div>
                        </div>
                        <div v-else-if="playWallets?.length === 0" class="p-4 text-center text-slate-400">
                            <!-- No wallets found. -->
                            <img src="@/assets/set/plus-g.svg" class="w-12 md:w-8 h-12 md:h-8 mb-3 mx-auto">
                             <div class="text-center mb-5">Create your first play wallet to work on the platform.</div>
                             <button @click="showWallet()" class="b4 ba mx-auto">Create Wallet</button>
                             <div class="mt-3 mx-auto">
                                 <router-link :to="{ name: 'docs_wallets' }" class="linkb mt-1 mx-auto" @click="showWalletList = false">Learn More</router-link>
                             </div>
                        </div>
                        <div v-else>
                            <div v-for="wallet in playWallets" 
                            :key="wallet.wallet_id"
                            @click="selectWallet(wallet)"
                            class="px-4 py-2 hover:bg-slate-700 cursor-pointer"
                            :class="{'bg-slate-700': selectedWallet?.wallet_id === wallet.wallet_id}">
                                <!-- Shows only non archived wallets -->
                                <div class="flex items-center " v-if="wallet.wallet_status === 1">
                                    <div class="flex-1">
                                        <div class="text-sm font-medium">{{ wallet.wallet_name }}</div>
                                        <div class="text-xs text-slate-400">{{ formatWallet(wallet.wallet_address, 6) }}</div>
                                    </div>
                                    <div class="ml-auto items-end font-mono text-xs c2 text-right flex flex-col gap-1">
                                        <div class="w-fit">{{ wallet.wallet_balance?.sol }} SOL</div>
                                        <div class="flex flex-row gap-1 text-sm font-mono c3 items-center w-fit" v-if="wallet.wallet_reserved > 0">
                                            <img src="@/assets/set/lock.svg" class="w-3 h-3 mb-1">
                                            <span class=" whitespace-nowrap">{{ formatSOL(wallet.wallet_reserved) }} SOL</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="!noWallets" class="pb-3 pl-3">
                        <router-link :to="{ name: 'profile_wallets' }" @click="showWalletList = false" class="b4 bb">Manage Wallets</router-link>
                    </div>
                </div>
                <div v-if="showNoWallet" 
                     class="absolute right-0 top-full mt-2 w-64 bg-slate-800 rounded-lg border border-slate-700 shadow-lg z-50"
                     style="transform-origin: top right;">
                    <div class="max-h-[300px] overflow-y-auto py-2">
                        <div class="absolute top-3 right-3">
                                <img src="@/assets/set/close.svg" class="w-5 h-5 cursor-pointer" @click="showNoWallet = false">
                            </div>
                        <div v-if="isLoading" class="p-4 text-center">
                            <div class="spinner-m mx-auto"></div>
                        </div>
                   
                        <div class="p-4 text-center">

                            <img src="@/assets/set/wallet.svg" class="w-12 md:w-8 h-12 md:h-8 mb-3 mx-auto">

                             <div class="text-center text-lg font-bold mb-2 c1">Add Play Wallet</div>
                             <div class="text-center mb-3  text-slate-400">Set up your Play Wallet on Solana to start working on the platform.</div>
                             <div class="flex flex-row gap-3 items-center justify-center">
                                 <button @click="showWallet()" class="b4 bc">Create Wallet</button>
                                 <button @click="showNoWallet = false" class="b4 ba ">Later</button>
                             </div>
                             <div class="mt-3 mx-auto">
                                 <router-link :to="{ name: 'docs_wallets' }" class="linkb mt-1 mx-auto" @click="showWalletList = false">What Is It?</router-link>
                             </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ml-auto text-sm shrink-0">
                <router-link :to="{ name: 'portfolio_positions' }" class="flex flex-row gap-1 md:gap-2 w-fit px-1.5 md:px-2 py-1 rounded-lg border border-[#405365] md:pr-4">
                    <img src="@/assets/set/coins.svg" class="w-4 md:w-5 h-4 md:h-5">
                    <span class="whitespace-nowrap">
                        <!-- <span class="mr-1 font-bold">54</span>  -->
                        <span class="max-md:hidden">Positions</span>
                    </span>
                </router-link>
            </div>

            <router-link :to="{ name: 'profile' }" class="flex flex-row gap-1 md:gap-2 w-fit px-1.5 md:px-2 py-1 rounded-lg border border-[#405365] shrink-0  h-[30px]">
                <img src="@/assets/set/user.svg" class="w-4 md:w-5 h-4 md:h-5">
                <span class="max-md:hidden">Profile</span>
            </router-link>
            <!-- <router-link :to="{ name: 'profile_upgrade' }" class="h-[31px] flex flex-row gap-1 md:gap-3 w-fit px-1.5 md:px-2 py-1 rounded-lg border shrink-0 bg-blue-700 bg-opacity-50 border-blue-400 text-blue-100">
                <img src="@/assets/set/up.svg" class="w-3 md:w-4 h-3 md:h-4 my-auto">
            </router-link> -->

        </div>

    </div>

</template>

<script>
import { mapState, mapActions } from 'vuex';
import api from '@/api';

export default {
    props: {
        showLiner: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            search: '',
            closeSearch: false,
            isSearchFocused: false,
            showWalletList: false,
            showNotifList: false,
            // notifList: [],
            showNoWallet: false,
            isLoadingWallets: false,
            isLoadingNotifs: false,
        }
    },
    async mounted() {
        // Add global keyboard listener
        window.addEventListener('keydown', this.handleKeyPress);
        await this.fetchWallets();
        if (this.playWallets.length === 0) {
            this.showNoWallet = true;
        }
        if (this.isAuth) {
            // await this.fetchNotifs();
            await this.initializeNotifications();
        }
        document.addEventListener('click', this.closeDropdowns);
    },
    beforeUnmount() {
        // Clean up listener
        window.removeEventListener('keydown', this.handleKeyPress);
        document.removeEventListener('click', this.closeDropdowns);
    },
    watch: {
        'playWallets.length'() {
            if (this.playWallets.length > 0) {
                this.showNoWallet = false;
            }
        }
    },
    computed: {

        //* User Auth *//
        ...mapState(['user', 'userData', 'jwt']),
        isAuth() { return !!this.jwt; },
        ...mapState('playwallet', ['playWallets', 'selectedWallet', 'isLoading', 'noWallets']),
        ...mapState('ws', ['recentNotifications']),

    },
    methods: {
        ...mapActions('playwallet', ['fetchWallets']),
        ...mapActions('modals', ['showAuthModal', 'hideAuthModal']),

        ...mapActions('ws', ['initializeNotifications']),

        // async fetchNotifs() {
        //     this.isLoadingNotifs = true;
        //     try {
        //         const response = await api.post('user/notif/list', { 
        //             data: { limit: 10 },
        //         });
        //         if (response.data.result === 'success') {
        //             this.notifList = response.data.notifs;
        //         }
        //     } catch (error) {
        //         console.error('Error fetching notifications:', error);
        //     } finally {
        //         this.isLoadingNotifs = false;
        //     }
        // },
        
        //* Search *//
        handleKeyPress(event) {
            // Check if the pressed key is '/' and no input/textarea is focused
            if (event.key === '/' && 
                document.activeElement.tagName !== 'INPUT' && 
                document.activeElement.tagName !== 'TEXTAREA') {
                event.preventDefault(); // Prevent '/' from being typed
                this.isSearchFocused = true;
                this.$refs.searchInput.focus();
                
                // Reset animation by removing and adding class
                this.$nextTick(() => {
                    const element = this.$refs.searchInput.parentElement.parentElement;
                    element.classList.remove('search-focus-animation');
                    void element.offsetWidth; // Trigger reflow
                    element.classList.add('search-focus-animation');
                });
            }
        },
        handleSearch() {
            if (this.search.length > 2 || this.search.length === 0) {
                if (this.$route.name !== 'list') {
                this.$router.push({ name: 'list', query: { search: this.search } });
                } else {
                this.$router.push({ query: { ...this.$route.query, search: this.search } });
                }
            }
            if (this.search.length > 2) {
                this.closeSearch = true;
            }
        },
        finishSearch() {
            this.closeSearch = false;
            this.search = '';
            this.closeSearch = false;
            this.handleSearch();
        },
        showWallet() {
            this.$emit('showWallet');
            this.showWalletList = false;
        },
        showAuth() {
            // this.$emit('showAuth');
            this.showAuthModal();
        },
        showWelcome() {
            this.$emit('showWelcome');
        },
        clickLogo() {
            this.$emit('clickLogo');
        },
        toggleWalletList(e) {
            e.stopPropagation();
            this.showWalletList = !this.showWalletList;
        },
        
        selectWallet(wallet) {
            this.$store.commit('playwallet/setSelectedWallet', wallet);
            this.showWalletList = false;
        },
        
        toggleNotifList(e) {
            e.stopPropagation();
            this.showNotifList = !this.showNotifList;
            // Close wallet list when opening notif list
            if (this.showNotifList) {
                this.showWalletList = false;
            }
        },
        
        closeDropdowns(e) {
            // Close wallet list if clicked outside
            if (!e.target.closest('.relative')) {
                this.showWalletList = false;
                this.showNotifList = false;
            }
        },
        getNotifTypeText(type) {
            const types = {
                1100: 'Pending Order',
                1200: 'Order Complete',
                1210: 'Order Failed',
                2100: 'Pending Transfer',
                2110: 'Transfer Complete',
                2120: 'Transfer Failed',
                2200: 'Pending Launch',
                2210: 'Launch Complete',
                2220: 'Launch Failed',
                2500: 'Pending Boost',
                2501: 'Boost Active',
                2510: 'Boost Failed',
                2800: 'Pending Action',
                3100: 'Action Complete'
            };
            return types[type] || 'Notification';
        },
        formatValue(value) {
            return parseFloat(value).toFixed(4);
        }
    }
}

</script>

<style scoped>
@keyframes searchFocusBlink {
    0%, 100% { border-color: #89D2C3; }  /* Keep border color on focus */
    25%, 75% { border-color: #89D2C3; }
}

.search-focus-animation {
    animation: searchFocusBlink 0.4s ease-in-out;
}

/* Add this new class */
.search-focused {
    border-color: #89D2C3 !important;
}
</style>