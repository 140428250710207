<template>
    
    <div  class="p-10 w-full h-screen">
        <div class="mx-auto my-auto text-center pt-20">
            <img src="@/assets/air.svg" class="w-16 md:w-22 mb-4 mx-auto">
            <div class="spinner-32 mx-auto mb-4"></div>
            Loading...
        </div>
    </div>

</template>