<template>

    <div class="
    md:fixed md:bottom-0 md:left-14 md:right-0 md:h-8 
    border-t border-slate-700 

    bg-[#091622] bg-opacity-75 backdrop-blur-sm
    z-[600] text-sm text-slate-500">
<!-- 
    <div class="
    md:fixed md:bottom-0 md:left-14 md:right-0 md:h-8 border-t 
    bg-[#091622] bg-opacity-90 backdrop-blur-sm
    border-slate-700 z-[600] text-sm text-slate-500">
        <div class="flex flex-col md:flex-row gap-0 max-md:pb-16 max-md:mb-2"> -->

        <div class="flex flex-col md:flex-row gap-0 max-md:pb-16 max-md:mb-2">

            <!-- <div class="p-1 px-3 flex flex-row gap-4 md:hidden max-md:w-full max-md:border-b border-slate-700">
                <router-link :to="{ name: 'docs' }" class="text-lg">
                    <img src="@/assets/set/book.svg" class="w-5 h-5 mb-1 mr-2 inline-block">Documentation
                </router-link>
                <router-link :to="{ name: 'docs' }" class="">
                    <img src="@/assets/set/book.svg" class="w-5 h-5 mb-1 mr-2 inline-block">Sniper
                </router-link>
                <router-link :to="{ name: 'docs' }" class="">
                    <img src="@/assets/set/book.svg" class="w-5 h-5 mb-1 mr-2 inline-block">Referals
                </router-link>
                <router-link :to="{ name: 'docs' }" class="">
                    <img src="@/assets/set/book.svg" class="w-5 h-5 mb-1 mr-2 inline-block">Academy
                </router-link>
            </div> -->

            <div class="flex flex-wrap md:hidden border-b border-slate-700">
                
                <router-link :to="{ name: 'docs' }" class="text-lg p-2 ">
                    <img src="@/assets/set/book.svg" class="w-4 h-4 mb-1 mr-2 inline-block">Docs
                </router-link>
                <router-link :to="{ name: 'affiliate' }" class="text-lg p-2">
                    <img src="@/assets/set/earn.svg" class="w-4 h-4 mb-1 mr-2 inline-block">Affiliate
                </router-link>
                <router-link :to="{ name: 'invite' }" class="text-lg p-2 ">
                    <img src="@/assets/set/friends.svg" class="w-4 h-4 mb-1 mr-2 inline-block">Invite
                </router-link>

            </div>

            <!-- <div class="flex flex-row gap-0 max-md:w-full max-md:border-b border-slate-700 "> -->
                <!-- 
                <router-link :to="{ name: 'docs_token' }" class="p-1 px-3 max-md:pb-2 max-md:pt-2 max-md:ml-auto">
                    $AIR &mdash; Launch Soon!
                    <img src="@/assets/set/open-g.svg" class="w-4 h-4 mb-1 ml-1 opacity-50 mt-1 inline-block">
                </router-link> 
                -->
            <!-- </div> -->

            <div class="md:ml-auto flex flex-row items-center w-full max-md:border-b max-md:border-slate-700 max-md:shadow-md max-md:shadow-slate-950">

                <div class="p-1 px-3 max-md:pb-2 max-md:pt-2 border-slate-700 border-r"
                     :class="{ 'price-blink': priceChanged }">
                    $SOL = ${{ solPrice }}
                </div>

                <div class="flex-grow"></div>
                
                <div class="p-1 px-4 border-l border-slate-700 ml-auto max-md:pb-2 max-md:pt-2">
                    <a href="https://x.com/airpumpfun" target="_blank" class="">
                        Follow
                        <img src="@/assets/social/x.svg" class="w-4 h-4 mb-1 ml-1 inline-block opacity-50">
                    </a>
                </div>
                <div class="p-1 px-3 border-l border-slate-800">
                    <a href="https://t.me/airpumpfun" target="_blank" class="">
                        Announcements
                        <img src="@/assets/social/t.svg" class="w-5 h-5 mb-1 ml-1 inline-block opacity-50">
                    </a>
                </div>

            </div>

        </div>
    </div>

</template>

<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            priceChanged: false,
            solPrice: null
        }
    },
    computed: {
        ...mapState('ws', ['price'])
    },
    watch: {
        'price.sol_price': {
            handler(newPrice) {
                if (newPrice !== this.solPrice) {
                    this.solPrice = newPrice;
                    this.blinkPrice();
                }
            },
            immediate: true
        }
    },
    methods: {
        blinkPrice() {
            this.priceChanged = true;
            setTimeout(() => {
                this.priceChanged = false;
            }, 600);
        }
    }
}

</script>

<style scoped>
.price-blink {
    animation: blink 0.3s ease-in-out 2;
}

@keyframes blink {
    0% {
        color: rgb(148 163 184); /* slate-400 */
    }
    50% {
        color: rgb(234 179 8); /* yellow-500 */
    }
    100% {
        color: rgb(148 163 184); /* slate-400 */
    }
}
</style>