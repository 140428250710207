<template>

    <div class="z-[700] group
    fixed bottom-0 left-0 
    md:w-14 max-md:w-full

    bgc md:border-r border-slate-700  
    md:flex md:flex-col

    md:opacity-65 hover:opacity-100
    max-md:bg-opacity-65
    
    max-md:h-16 max-md:text-xs

    max-md:border-t max-md:border-slate-700 
    md:text-lg bg-[#091622] max-mk:w-[50px] 
    max-md:items-center max-md:text-center text-base max-md:menushadow max-md:uppercase
    
    md:hover:w-[180px] transition-all duration-200 hover:shadow-xl hover:shadow-slate-950
     max-md:backdrop-blur-md
    overflow-hidden md:pb-2
    " :class="showLiner ? 'md:top-8' : 'md:top-0'"
    >
    <!-- , isAuth ? '' : 'max-md:hidden' -->
    <!--max-md:text-sm-->

        <div class="group-hover:border-b border-slate-800 px-3 h-16 max-md:hidden ">
            <!-- h-[52px] -->
            <button @click="clickLogo()" class="flex flex-row gap-3 w-[200px] ml-0 pt-5">
            <!-- <router-link to="/" class="flex flex-row gap-3 w-[200px] ml-0 pt-5"> -->
                <img src="@/assets/air.svg" class="w-7 h-7 mr-1">
                <img src="@/assets/logo_text.png" class="h-[20px] mt-2">
            <!-- </router-link> -->
            </button>
        </div>

        <div class="md:p-3 p-2 flex md:flex-col flex-row gap-5 group-hover:border-b border-slate-800">
            <router-link :to="{ name: 'dash' }"   :class="{ 'c1': isActiveTab('dash') }"       class="flex flex-col md:flex-row gap-1 md:gap-4 md:w-fit w-1/5 hover:text-[#C5DD76]"><img src="@/assets/set/speed-2.svg" class="w-7 h-7 mx-auto"><span class="">Dashboard</span></router-link>
            <!-- <router-link :to="{ name: 'trends' }" :class="{ 'c1': isActiveTab('trends') }"     class="flex flex-col md:flex-row gap-1 md:gap-4 md:w-fit w-1/5 hover:text-[#C5DD76]"><img src="@/assets/set/fire.svg" class="w-7 h-7 mx-auto"><span class="">Trends</span></router-link> -->
            <router-link :to="{ name: 'list' }"   :class="{ 'c1': isActiveTab('list') }"       class="flex flex-col md:flex-row gap-1 md:gap-4 md:w-fit w-1/5 hover:text-[#C5DD76]"><img src="@/assets/set/coins.svg" class="w-7 h-7 mx-auto"><span class="">Coins</span></router-link>
            <!-- <router-link :to="{ name: 'list' }"   :class="{ 'c1': isActiveTab('list') }"       class="flex flex-col md:flex-row gap-1 md:gap-4 md:w-fit w-1/5 hover:text-[#C5DD76]"><img src="@/assets/set/shades-g.svg" class="w-7 h-7 mx-auto"><span class="">Agents</span></router-link> -->
            <router-link :to="{ name: 'portfolio' }"   :class="{ 'c1': isActiveTab('portfolio') }"       class="flex flex-col md:flex-row gap-1 md:gap-4 md:w-fit w-1/5 hover:text-[#C5DD76]"><img src="@/assets/set/bag.svg" class="w-7 h-7 mx-auto"><span class="">Portfolio</span></router-link>
            <!-- <router-link :to="{ name: 'my' }" :class="{ 'c1': isActiveTab('my') }"        class="flex flex-col md:flex-row gap-1 md:gap-4 md:w-fit w-1/5 hover:text-[#C5DD76]"><img src="@/assets/set/bag.svg" class="w-7 h-7 mx-auto"><span class="">Bags</span></router-link> -->
            <!-- <router-link :to="{ name: 'invite' }"   :class="{ 'c1': isActiveTab('invite') }"       class="flex flex-col md:flex-row gap-1 md:gap-4 md:w-fit w-1/5 hover:text-[#C5DD76]"><img src="@/assets/set/mail.svg" class="w-7 h-7 mx-auto"><span class="">Invite</span></router-link> -->
            <router-link :to="{ name: 'my' }"   :class="{ 'c1': isActiveTab('my') }"       class="flex flex-col md:flex-row gap-1 md:gap-4 md:w-fit w-1/5 hover:text-[#C5DD76]"><img src="@/assets/set/coin.svg" class="w-7 h-7 mx-auto"><span class=" whitespace-nowrap">My Coins</span></router-link>
            <!-- <router-link :to="{ name: 'drop' }"   :class="{ 'c1': isActiveTab('drop') }"       class="flex flex-col md:flex-row gap-1 md:gap-4 md:w-fit w-1/5 hover:text-[#C5DD76]"><img src="@/assets/set/drop.svg" class="w-7 h-7 mx-auto"><span class="">Drop</span></router-link> -->
            <!-- <router-link :to="{ name: 'profile' }"   :class="{ 'c1': isActiveTab('profile') }" class="flex flex-col md:flex-row gap-1 md:gap-4 md:w-fit w-1/5 md:hidden"><img src="@/assets/set/user.svg" class="w-7 h-7 mx-auto"><span class="">Profile</span></router-link> -->
            <router-link :to="{ name: 'start' }"   :class="{ 'c1': isActiveTab('start') }" class="flex flex-col md:flex-row gap-1 md:gap-4 md:w-fit w-1/5 md:hidden"><img src="@/assets/set/rocket.svg" class="w-7 h-7 mx-auto"><span class="">Launch</span></router-link>
        </div>

        <div class="p-2 group-hover:p-3 mb-1 group-hover:border-b border-slate-800 max-md:hidden">
            <router-link :to="{ name: 'start' }" :class="{ 'c1': isActiveTab('start') }" class="group-hover:hidden flex flex-col md:flex-row gap-1 md:gap-4 md:w-fit max-md:hidden shrink-0"><img src="@/assets/set/rocket.svg" class="w-7 h-7 mx-auto ml-1 mt-1">
                <!-- <div class="b3 bc">Launch</div> -->
            </router-link>
            <router-link :to="{ name: 'start' }" class="hidden group-hover:block w-[200px]">
                <div class="b4 bc">
                    <img src="@/assets/set/rocket-d.svg" class="w-7 h-7 mx-auto">
                    <div class="">Launch</div>
                </div>
                <div class="text-sm mt-2">Start your coin today</div>
            </router-link>
        </div>

        <div class="flex-grow">

        </div>
    
        <div class="pt-3 px-3 pb-1 flex flex-col gap-5">
            <router-link :to="{ name: 'invite' }" :class="{ 'c1': isActiveTab('invite') }" class="flex flex-col md:flex-row gap-1 md:gap-4 md:w-fit max-md:hidden hover:text-[#C5DD76]"><img src="@/assets/set/friends.svg" class="w-7 h-7 mx-auto"><span class="">Invite</span></router-link>
        </div>
        <div class="p-3 flex flex-col gap-5">
            <router-link :to="{ name: 'affiliate' }" :class="{ 'c1': isActiveTab('affiliate') }" class="flex flex-col md:flex-row gap-1 md:gap-4 md:w-fit max-md:hidden hover:text-[#C5DD76]"><img src="@/assets/set/earn.svg" class="w-7 h-7 mx-auto"><span class="">Affiliate</span></router-link>
        </div>
        <div class="p-3 flex flex-col gap-5">
            <router-link :to="{ name: 'docs' }" :class="{ 'c1': isDocs }" class="flex flex-col md:flex-row gap-1 md:gap-4 md:w-fit max-md:hidden hover:text-[#C5DD76]"><img src="@/assets/set/book.svg" class="w-7 h-7 mx-auto"><span class="">Docs</span></router-link>
        </div>
        <!-- <div class="p-3 flex flex-col border-t border-slate-800 max-md:hidden">
            <router-link :to="{ name: 'profile' }"   :class="{ 'c1': isActiveTab('profile') }" class="flex flex-col md:flex-row gap-1 md:gap-4 md:w-fit max-md:hidden"><img src="@/assets/set/user.svg" class="w-7 h-7 mx-auto"><span class="">Profile</span></router-link>
             <div class="hidden group-hover:flex flex-row gap-2 items-center ml-[45px]">
                <span class="text-sm">{{ userData?.user_nick }}</span>
            </div> 
            < <img src="@/assets/set/exit.svg" class="w-5 h-5">
            <div class="text-sm">Logout</div>
        </div>-->

    </div>

</template>

<script>
import { toHandlers } from 'vue';
import { mapState } from 'vuex';

export default {
    props: {
        showLiner: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState(['isAuth']),
        ...mapState(['userData']),
        isDocs() {
            // Check if current route name is 'docs' or starts with 'docs_'
            // alert('isDocs='+this.$route.name);
            return this.$route.name === 'docs' || 
                   (this.$route.name && this.$route.name.startsWith('docs_'));
        },
    },
    methods: {

        clickLogo() {
            this.$emit('clickLogo');
        },
        showWelcome() {
            this.$emit('showWelcome');
        }

    }
}
</script>