<template>

    <div class="flex-1 overflow-y-auto p-3 scrollbar-hide">

        <div class="flex flex-row justify-between gap-2 items-center mb-4">
            <div class="text-center flex-grow">
                <div v-if="!isEditing" class="flex items-center justify-between">
                    <h3 class="c2">{{ selectedWallet?.wallet_name || 'Unnamed Wallet' }}</h3>
                    <img src="@/assets/set/edit.svg" 
                         @click="startEdit" 
                         class="w-4 h-4 cursor-pointer ml-2">
                </div>
                <div v-else class="flex items-center flex-row gap-3">
                    <input type="text" 
                           v-model="editedName" 
                           class=" py-1 px-2 text-lg"
                           ref="nameInput">
                    <div class="flex gap-2 flex-row flex-grow">
                        <button v-if="!isSaving" 
                                @click="saveName" 
                                class="b5 bc">Save</button>
                        <button v-if="!isSaving" 
                                @click="cancelEdit" 
                                class="b5 ba ml-auto">Cancel</button>
                        <div v-if="isSaving" class="spinner-m"></div>
                    </div>
                </div>
                <!-- <div v-if="selectedWallet?.wallet_status === 2" class="c3 text-sm text-left">
                    <img src="@/assets/set/archive.svg" class="w-4 h-4 inline-block mr-2">
                    <span>Archived wallet</span>
                </div> -->
            </div>
        </div>

        <div class="mb-4 p-3 bg-slate-900 rounded-xl shadow-sm shadow-slate-950" v-if="selectedWallet?.wallet_status != 2">

            <div class="">
                <div class="flex flex-col gap-3 text-center">
                    <div class="text-sm text-slate-400 uppercase">
                        Balance
                    </div>
                    <div class="text-4xl flex flex-row gap-2 items-center justify-center">
                        <span v-if="isBalanceRefreshing" class="spinner-m inline-block align-middle mr-2"></span>
                        <span v-else>{{ formatSolBalance }}</span>
                        <span>SOL</span>
                        <button class="bb b4" @click="refreshBalance">
                            <img src="@/assets/set/refresh.svg" 
                                 class="w-5 h-5 inline transition-transform duration-300" 
                                 :class="{'animate-spin': isBalanceRefreshing}">
                        </button>
                    </div>
                    <div class="text-base c1">
                        ~${{ walletUsdValue }}
                    </div>
                    <div class="w-full flex flex-row gap-1 text-sm items-center bg-yellow-700/20 rounded-xl px-2 py-1 justify-between" v-if="walletReserved > 0">
                        <div class="c3 uppercase">
                            Reserved for orders
                        </div>
                        <div class="ml-auto flex flex-row gap-2 font-mono c3 ">
                            <img src="@/assets/set/lock.svg" class="w-3 h-3 mt-1">
                            <span class=" whitespace-nowrap">{{ formatSOL(walletReserved) }} SOL</span>
                        </div>
                    </div>

                </div>
            </div>

        </div>
        <div v-else class="mb-4 p-5 bg-slate-900 rounded-xl shadow-sm shadow-slate-950">
            <div class="text-lg flex flex-col gap-3 justify-center text-center">
                <!-- <img src="@/assets/set/archive.svg" class="w-24 h-24 mx-auto"> -->
                <img src="@/assets/set/box-i.svg" class="w-20 h-20 mx-auto ">
                <div class="mb-3 text-xl">Archived wallet</div>
                <button class="b3 bb w-fit mx-auto" @click="toggleView('export')">Export Private Key</button>
            </div>
        </div>

        <div class="flex flex-row justify-between gap-2 mb-3" v-if="selectedWallet?.wallet_status != 2">
            <div @click="toggleView('deposit')" class=" w-1/3 bg-slate-900 rounded-xl p-4 flex flex-col items-center justify-center hover:bg-slate-950 cursor-pointer">
                <img src="@/assets/set/wallet-deposit.svg" class="w-8 h-8 m-1">
                <div class=" text-slate-400">Deposit</div>
            </div>
            <div @click="toggleView('send')"  class="w-1/3 bg-slate-900 rounded-xl p-4 flex flex-col items-center justify-center hover:bg-slate-950 cursor-pointer">
                <img src="@/assets/set/wallet-send.svg" class="w-8 h-8 m-1">
                <div class=" text-slate-400">Send</div>
            </div>
            <!-- TODO: pass wallet address to assets -->
            <router-link :to="{ name: 'portfolio_assets', query: { wallet: selectedWallet?.wallet_id }}" @click="hideWallet()" class="w-1/3 bg-slate-900 rounded-xl p-4 flex flex-col items-center justify-center hover:bg-slate-950 cursor-pointer">
                <img src="@/assets/set/coins-g.svg" class="w-7 h-7 m-1">
                <div class="mt-1 text-slate-400">Coins</div>
            </router-link>
            <router-link :to="{ name: 'portfolio_orders', query: { wallet: selectedWallet?.wallet_id }}" @click="hideWallet()" class="w-1/3 bg-slate-900 rounded-xl p-4 flex flex-col items-center justify-center hover:bg-slate-950 cursor-pointer">
                <img src="@/assets/set/wallet-bag.svg" class="w-7 h-7 m-1">
                <div class="mt-1 text-slate-400">Orders</div>
            </router-link>
            <!-- <div @click="toggleView('bridge')" class="w-1/3 bg-slate-900 rounded-xl p-4 flex flex-col items-center justify-center hover:bg-slate-950 cursor-pointer">
                <img src="@/assets/set/wallet-bridge.svg" class="w-10 h-10">
                <div class="text-sm text-slate-400">Bridge</div>
            </div> -->
        </div>

        <div class=" bg-slate-900 rounded-xl p-4">

            <h5 class="mb-3">Transfers</h5>

            <!-- Tabs -->
            <!-- COOL! -->
            <!-- <div class="max-ms:hidden flex flex-row gap-0 rounded-lg border-t border-r border-l border-slate-600 overflow-hidden">
                <button @click="toggleTab(0)" :class="viewTab === 0 ? 'c2 bg-gradient-to-t from-slate-800' : 'text-slate-400'" class="border-r border-slate-600 hover:text-slate-300 max-mv:px-3 py-2 px-4">All</button>    
                <button @click="toggleTab(1)" :class="viewTab === 1 ? 'c2 bg-gradient-to-t from-slate-800' : 'text-slate-400'" class="hover:text-slate-300 max-mv:px-3 border-l border-slate-600 py-2 px-4 ml-auto">Transfers</button>    
                <button @click="toggleTab(2)" :class="viewTab === 2 ? 'c2 bg-gradient-to-t from-slate-800' : 'text-slate-400'" class="hover:text-slate-300 max-mv:px-3 border-l border-slate-600 py-2 px-4">Trades</button>
            </div> -->
        
            <div class="max-ms:hidden mb-3 flex flex-col gap-0 rounded-lg border border-slate-600 overflow-hidden">
                
                <div v-if="isLoadingTransfers" class="p-20">
                    <div class="spinner-32 mx-auto"></div>
                </div>
                <div v-else-if="transfers?.length === 0" class="p-20">
                    <img src="@/assets/set/box-e.svg" class="w-20 h-20 mx-auto mb-3">
                    <div class="text-slate-400 text-center">No transactions found</div>
                </div>
                <div v-else class="flex flex-col w-full pl-4 pr-3 py-2 gap-2 border-b border-slate-700 items-center">

                    <div v-for="transfer in transfers" :key="transfer.transfer_id" 
                        class="flex w-full flex-grow gap-2 border-b border-slate-700 items-center"
                        :class="{ 'opacity-50': transfer.transfer_status === 3 }">
                        <div class="flex-grow py-2">
                            <div class="text-slate-400">
                                {{ transfer.transfer_type === 1 ? 'Transfer to' : 'Deposit from' }}
                            </div>
                            <div class="">
                                <span>{{ formatWallet(transfer.transfer_to_address) }}</span>
                                <a :href="`https://solscan.io/address/${transfer.transfer_to_address}`" target="_blank">
                                    <img src="@/assets/set/find-r.svg" class="h-4 w-4 ml-2 inline">
                                </a>
                            </div>
                        </div>
                        <div class="w-1/3 py-2 flex flex-col gap-0 text-right">
                            <div class="font-mono text-sm" :class="transfer?.transfer_type === 1 ? 'c8' : 'c3'">
                                {{ transfer?.transfer_type === 1 ? '-' : '' }}{{ formatSol(transfer?.transfer_amount, 5) }} SOL
                            </div>
                            <div v-if="transfer?.transfer_status === 3" class="text-sm text-red-400">Failed</div>
                            <div v-else class="text-sm text-slate-400">${{ formatSolToUsd(transfer?.transfer_amount) }}</div>
                        </div>
                        <div class="w-4">
                            <!--
                            3 - failed
                            2 - completed
                            1 - processing
                            -->
                            <div v-if="transfer.transfer_status === 1" class="flex flex-col items-center">
                                <div class="spinner-m"></div>
                            </div>
                            <div v-else-if="transfer.transfer_status === 2" class="flex flex-col items-center">
                                <img src="@/assets/set/check.svg" class="h-3 mb-5 mx-auto">
                            </div>
                            <div v-else-if="transfer.transfer_status === 3" class="flex flex-col items-center">
                                <img src="@/assets/set/close.svg" class="h-3 mb-5 mx-auto">
                            </div>
                            <!-- <div v-else class="flex flex-col items-center">
                                {{ transfer.transfer_status }}
                            </div> -->
                            <div v-if="transfer.transfer_tx_hash" class="flex flex-col items-center">
                                <a :href="`https://solscan.io/tx/${transfer.transfer_tx_hash}`" target="_blank">
                                    <img src="@/assets/set/dots-v.svg" class="h-3 mb-5 mx-auto">
                                </a>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- Pagination -->
                <div class="flex flex-col gap-2 px-4 pb-5 pt-2" v-if="totalPages>1">
                    <div class="text-slate-400 mt-2">Page {{ currentPage }} of {{ totalPages }}</div>
                    <div class="flex flex-row gap-2">
                        <div class="b5 bgf cursor-pointer" 
                            :class="{ 'opacity-50': currentPage === 1 }"
                            @click="prevPage">&larr;</div>
                        
                        <template v-for="page in totalPages" :key="page">
                            <div class="b5" 
                                :class="page === currentPage ? 'ba' : 'bgf cursor-pointer'"
                                @click="goToPage(page)">
                                {{ page }}
                            </div>
                        </template>
                        
                        <div class="b5 bgf cursor-pointer" 
                            :class="{ 'opacity-50': currentPage === totalPages }"
                            @click="nextPage">&rarr;</div>
                    </div>
                </div>

            </div>

        </div>

    </div>

</template>

<script>
import { mapState, mapActions } from 'vuex';
import api from '@/api';

export default {
    props: {
        view: String
    },
    data() {
        return {
            viewTab: 0,
            isEditing: false,
            editedName: '',
            isLoadingTransfers: false,
            walletReserved: '-',
            transfers: [],
            currentPage: 1,
            totalPages: 1,
            totalTransfers: null
        }
    },
    computed: {

        ...mapState('ws', ['price']),
        ...mapState('ws', ['walletTransfersMessages']),

        ...mapState('playwallet', ['playWallets', 'selectedWallet', 'isWalletsLoading', 'isBalanceRefreshing']),
        
        formatSolBalance() {
            return this.formatSol(this.selectedWallet?.wallet_balance?.sol, 9);
        },
        walletUsdValue() {
            return this.formatSolToUsd(this.selectedWallet?.wallet_balance?.sol);
        },
        walletReserved() {
            return this.selectedWallet?.wallet_reserved;
        }
    },
    watch: {

        walletTransfersMessages: {
            handler(messages) {
                // Handle any transfer update
                if (messages && Object.keys(messages).length > 0) {
                    const latestUpdate = Object.values(messages)[0];
                    if (latestUpdate && latestUpdate.transfers && latestUpdate.transfers.length > 0) {
                        // Refresh transfers list when we get an update
                        if(latestUpdate.transfers[0].wallet?.wallet_id === this.selectedWallet?.wallet_id) {
                            this.fetchTransfers();
                        }
                    }
                }
            },
            deep: true
        },

    },
    mounted() {
        this.fetchTransfers();
    },
    methods: {
        ...mapActions('playwallet', ['renameWallet', 'checkBalance']),

        async fetchTransfers(page = 1) {
            this.isLoadingTransfers = true;
            try {
                const response = await api.post('user/wallet/transfers', {
                    data: {
                        wallet: this.selectedWallet?.wallet_id,
                        page: page
                    }
                });
                if (response.data.status === 'success') {
                    this.transfers = response.data.transfers;
                    this.currentPage = response.data.pages.current;
                    this.totalPages = response.data.pages.last;
                } else {
                    console.error('Failed to fetch transfers:', response.data.message);
                }
            } catch (error) {
                console.error('Error fetching transfers:', error);
            } finally {
                this.isLoadingTransfers = false;
            }
        },

        toggleTab(tab) {
            this.viewTab = tab;
        },
        toggleView(view) {
            this.$emit('toggleView', view);
        },
        async refreshBalance() {
            try {
                await this.checkBalance();
            } catch (error) {
                console.error('Failed to refresh balance:', error);
                // Could add error notification here
            }
        },
        startEdit() {
            if (this.selectedWallet) {
                this.editedName = this.selectedWallet.wallet_name || '';
                this.isEditing = true;
                this.$nextTick(() => {
                    this.$refs.nameInput.focus();
                });
            }
        },
        cancelEdit() {
            this.isEditing = false;
            this.editedName = '';
        },
        async saveName() {
            if (!this.editedName.trim() || !this.selectedWallet) return;
            
            try {
                await this.renameWallet({
                    wallet_id: this.selectedWallet.wallet_id,
                    wallet_name: this.editedName.trim()
                });
                this.isEditing = false;
                this.editedName = '';
            } catch (error) {
                console.error('Failed to save wallet name:', error);
                // Could add error notification here
            }
        },
        hideWallet() {
            this.$emit('hideWallet');
        },
        goToPage(page) {
            if (page >= 1 && page <= this.totalPages) {
                this.fetchTransfers(page);
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.goToPage(this.currentPage - 1);
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.goToPage(this.currentPage + 1);
            }
        },
    }
}
</script>

<style scoped>
.animate-spin {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>