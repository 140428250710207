<template>

    <!-- max-md:bottom-24 md:bottom-5 -->
    <div @click="hideInform" v-if="information" 
            :key="animationKey"
            class="z-[1001] max-w-[600px] md:w-[80%] right-3 left-3 p-2 md:p-4 md:px-3 mx-auto fixed 
             text-white text-bold text-center max-md:text-sm
             shadow-2xl rounded-[10px] md:rounded-[18px]
             border
             bg-opacity-90  
             backdrop-blur-sm"
            :class="[
                {
                'text-[#C5DD76] border-slate-700 bg-slate-900': information.type === 'success',
                'text-red-200 border-red-800 bg-red-950': information.type === 'error',
                'text-yellow-200 border-yellow-800 bg-yellow-950': information.type === 'warning',
                'text-blue-200 border-blue-800 bg-blue-950': information.type === 'info',
                'text-slate-200 border-slate-500 bg-slate-800 ': information.type === 'load',
                },
                // Add dynamic bottom positioning based on notification
                notification ? 'bottom-32 md:bottom-28' : 'bottom-4 md:bottom-12'
            ]">
            <div class="flex flex-row items-center justify-center gap-4">
                <div class="spinner" v-if="information.type === 'load'"></div>
                <span v-html="sanitizedMessage"></span>
            </div>
    </div>

</template>

<script>

import { mapActions, mapState } from 'vuex';

export default {
    props: {
        information: {
            type: Object,
            required: false,
            default: null
        },
    },
    data() {
        return {
            animationKey: 0,
        }
    },
    computed: {
        ...mapState(['notification']),
        sanitizedMessage() {
            if (!this.information?.message) return '';
            return this.information.message;
        }
    },
    watch: {
        information() {
            this.animationKey += 1;
        }
    },
    methods: {
        ...mapActions(['hideInformation']),
        hideInform() {
            this.hideInformation();
        }
    }
}

</script>
