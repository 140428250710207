import { createApp } from 'vue'
import App from './App.vue'

import router from "./router";
import store from "./store";
import i18n from './i18n';

import { globalErrorHandler } from './utils/errors'
import Globals from './utils/func'; // import Globals from './utils/func';

import './assets/index.css';

const app = createApp(App);
app.config.errorHandler = globalErrorHandler
app.mixin(Globals);
app
.use(router)
.use(store)
.use(i18n)
.mount('#app')

// createApp(App)
//     .use(router)
//     .use(store)
//     .use(i18n)
//     .mount('#app');
