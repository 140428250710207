<template>

    <div class="flex-1 overflow-y-auto p-3 scrollbar-hide">

        <div class="flex flex-row justify-between gap-2 items-center mb-4">
            <div class="flex-grow px-4 p-2">
                <h2>Play Wallets</h2>
                <div>
                    At least one play wallet is required to work on the platform.
                </div>
                
            </div>
        </div>

        <div v-if="isWalletsLoading" class="p-20 bg-slate-900 rounded-xl w-full">  
            <div class="spinner-32 mx-auto"></div>
        </div>
        <div v-else-if="error" class="p-10 bg-slate-900 rounded-xl w-full"> 
            <div class="text-center text-red-500">{{ error }}</div>
            <button class="b4 bg mx-auto" @click="error = null">Try again</button>
        </div>
        <div v-else class="mb-4 p-3 bg-slate-900 rounded-xl shadow-sm shadow-slate-950">

            <div class=" pt-10">
                   <img src="@/assets/set/wallet.svg" class="w-12 md:w-18 h-12 md:h-18 mx-auto">
            </div>
            <div class="p-6 md:p-10 pt-2 text-lg mx-auto">
                    <div class="text-center mb-5">Create your first play wallet to work on the platform.</div>
                    <div class="flex justify-center"><button @click="handleCreateWallet()" class="b2 ba mx-auto"><img src="@/assets/set/plus-g.svg" class="w-5 h-5  inline-block"> Create Wallet</button></div>
            </div>

        </div>

        <div class="p-3">
            <div>
                Play wallets used for coin launches and running automated trading operations.
            </div>
            <div class="text-slate-400 text-sm">
                Export private keys of play wallets, import it to any digital wallet and manage all the assets as you would with regular wallets.
                </div>
            <div class="mt-3 mx-auto">
                <router-link :to="{ name: 'docs_wallets' }" class="linkb mt-1 mx-auto">Learn More</router-link>
            </div>
        </div>

    </div>

</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    props: {
        view: String
    },
    data() {
        return {
            isCreating: false,
            viewTab: 0,
            isEditing: false,
            error: null,
            editedName: ''
        }
    },
    computed: {
        ...mapState('ws', ['price']),
        ...mapState('playwallet', ['playWallets', 'selectedWallet', 'isWalletsLoading', 'isBalanceRefreshing']),
        

    },
    methods: {
        ...mapActions('playwallet', ['fetchWallets', 'createWallet']),

        async handleCreateWallet() {
            try {
                this.isCreating = true;
                // alert('STart creating waller')
                await this.createWallet();
                // Wallet list will be automatically refreshed after creation
                this.$parent.view = 'deposit'; // Show deposit view after wallet creation
            } catch (error) {
                console.error('Failed to create wallet:', error);
                this.error = error.message+' Please try again later.';
                // You might want to show an error notification here
            } finally {
                this.isCreating = false;
            }
        },
    }
}
</script>

<style scoped>
.animate-spin {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>