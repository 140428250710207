import store from '@/store';

// Global errors from main.js

export function globalErrorHandler(error, vm, info) {

    // console.error('Uncaught error:', error);
    // console.error('Error info:', info);

    // Get component name and file location if available
    const componentName = vm?.$options?._componentTag || vm?.$options?.name || 'Unknown Component';
    const fileName = vm?.$options?.__file || 'Unknown File';
    const errorLocation = error.stack?.split('\n')[1]?.trim() || 'Unknown Location';

    console.error('Uncaught error:', {
        error,
        component: componentName,
        file: fileName,
        location: errorLocation,
        info
    });

    console.error('Vue instance:', vm);

    let message = error.response?.data?.message || error.message || 'An unexpected error occurred';
    message = `Error in ${componentName} (${fileName}) - ${message}`;

    store.commit('SET_APP_ERROR', { code: 'Global', text: message });

    store.dispatch('showNotification', { message: message, type: 'error' });

}
  
// Local errors from Store

  /**
 * Handles errors consistently across the application
 * @param {Error} err - The error object
 * @param {string} context - The context where the error occurred (e.g., '[STORE/auth/authWalletSetInit]')
 * @returns {string} A formatted error message
 */

export function handleError(err, context) {
  let errorMessage = '';
  let errorStack = '';
  
  // Get stack trace
  if (err.stack) {
    errorStack = err.stack
      .split('\n')
      .slice(1) // Remove the first line as it contains the error message
      .map(line => line.trim())
      .join('\n');
  }
  
  // For axios errors
  if (err.response?.data?.message) {
    errorMessage = err.response.data.message;
  }
  // For regular errors
  else if (err.message) {
    errorMessage = err.message;
  }
  // Fallback
  else {
    errorMessage = 'An unexpected error occurred';
  }
  
  console.error(`${context} Error:`, {
    message: errorMessage,
    location: errorStack,
    originalError: err
  });
  
  return errorMessage;
}
