<template>

    <div class="flex-1 overflow-y-auto p-3 scrollbar-hide">
        <h3 class="c2">Send SOL</h3>

        <div v-if="isDone" class="bg-slate-900 rounded-xl p-4 flex flex-col gap-3 mt-3">
            <h2 class="c3">Transaction Sent</h2>
            <div class="flex flex-col gap-3">
                <div>
                    The transaction has been successfully signed and is now pending confirmation on the blockchain. 
                </div><div>
                    Please allow some time for network validation, normally it takes 1-2 minutes for the transaction to get thorugh. 
                </div>
                <div class="c1">Your wallet balance will be updated after the transaction is confirmed on the blockchain.</div>
                <div>You can track status in the wallet history.</div>
            </div>
        </div>
        <div v-else-if="isSending" class="bg-slate-900 rounded-xl text-center p-10">
            <div class="spinner-32 mx-auto mb-4"></div>
            <div class="text-center">Sending...</div>
        </div>
        <div v-else class="bg-slate-900 rounded-xl p-4 flex flex-col gap-3 mt-3">

            <div v-if="isError" class="bg-red-900 rounded-xl p-4 flex flex-col gap-3 mt-3">
                {{ errorText }}
            </div>

            <div class="flex flex-col gap-3 border-b border-slate-700 pb-3">
                <h5 class="c3">Transfer to</h5>
                <input type="text" v-model="toAddress" class="field" placeholder="Wallet address">
                <div class=" text-slate-400">
                    Recipient's Solana address.
                </div>
            </div>
            
            <div class="relative mb-4 flex flex-col gap-3">
                <h5 class="c3">Amount</h5>
                <div class="relative">
                    <input type="text" 
                            v-model="amount"
                            class="field w-full pr-16" 
                            placeholder="Amount">
                    <div class="absolute right-0 top-0 h-full flex items-center">
                        <span class="text-slate-900 mr-3">SOL</span>
                        <button class="b5 bg px-2 mr-2" @click="setMaxAmount">MAX</button>
                    </div>
                </div>
                <div class="flex justify-between mt-1 text-sm">
                    <!-- <span class="text-slate-400">Fee ~0.000005 SOL</span> -->
                    <span class="text-slate-400 ml-auto">Available {{ formatNumber(selectedWallet?.wallet_balance?.sol, 6) }} SOL</span>
                </div>
            </div>

            <!-- <button class="b2 ba">Send</button> -->

        </div>

    </div>

    <!-- Fixed Footer -->
    <div class="bg-slate-900 px-4 py-3 flex justify-between border-t border-slate-700 rounded-b-lg">
        <button class="b2 be" @click="toggleView('home')" v-if="!isDone">Cancel</button>
        <button class="b2 ba" @click="sendFunds()" v-if="!isSending && !isDone">Send</button>
        <button class="b2 ba" v-if="isSending && !isDone">Sending...</button>
        <button class="b2 ba" @click="toggleView('home')" v-if="isDone">Done</button>
    </div>

</template>

<script>
import { mapState, mapActions } from 'vuex';
import api from '@/api';

export default {
    data() {
        return {
            toAddress: '',
            amount: '0.1',
            isDone: false,
            isSending: false,
            isError: false,
            errorText: ''
        }
    },
    computed: {
        ...mapState('playwallet', ['selectedWallet']),
    },
    methods: {

        ...mapActions('playwallet', ['checkBalance']),
        ...mapActions(['showNotification']),

        async sendFunds() {

            this.isSending = true;
            this.isError = false; // Reset error state
            this.errorText = '';
            
            try {
                const response = await api.post(`user/wallet/transfer`, {
                    data: { 
                        wallet_id: this.selectedWallet.wallet_id,
                        to_address: this.toAddress,
                        amount: this.amount,
                        type: 1 // for SOL
                        // token_address: this.tokenAddress // for transfers of tokens 
                    }
                });
                if(response.data.status=='success') {
                    this.showNotification({ type: 'success', message: 'Transaction signed, wait until it is confirmed on the blockchaiomn' });
                    // this.toggleView('home');
                    this.isDone = true;
                }
                else {
                    this.isError = true;
                    this.errorText = response.data.error || response.data.message || 'Failed to send funds';
                    this.showNotification({ type: 'error', message: this.errorText });
                }
            }
            catch(error) {
                console.error('Failed to send funds:', error);
                this.isError = true;
                this.errorText = error.response?.data?.error || error.response?.data?.message || 'Failed to send funds';
                this.showNotification({ type: 'error', message: this.errorText });
            }
            finally {
                this.isSending = false;
            }
        },

        toggleView(view) {
            this.$emit('toggleView', view);
        },

        setMaxAmount() {
            this.amount = this.selectedWallet.wallet_balance.sol.toString();
        },
        
    }
}

</script>