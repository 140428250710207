<template>

    <div class="h-screen justify-center flex flex-col text-center">

        <div class="bg-slate-800 border border-slate-700 shadow-lg  shadow-slate-950 p-5 px-10 rounded-lg mx-auto">
            <img src="@/assets/set/warn.svg" class="h-16 w-16 mb-2 mx-auto">
            <div class="text-slate-200 text-lg c1 mb-2">Service Unavailable</div>
            <div class="text-slate-300 text-base mb-5">Please try again later</div>
            <div class="text-slate-500 text-xs max-w-[500px]">{{ appErrorText }}<br>#{{ appErrorCode }}</div>

            <div class="mt-5">
                <button class="b3 bc" @click="refresh()">Retry</button>
            </div>
        </div>

    </div>

</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState(['appErrorText', 'appErrorCode'])
    },
    methods: {
        refresh() {
            window.location.reload();
        }
    }
}

</script>