<template>

    <div class="flex-1 overflow-y-auto scrollbar-hide bg-slate-900">
        <div class="flex-row gap-2 ">
            <div class="flex flex-col gap-3 p-3 w-full">
                <h5 class="text-slate-400">Wallets</h5>

                <div class="flex flex-col gap-3 items-center justify-center mb-3">
                    <!-- Loading State -->
                    <div v-if="isWalletsLoading" class="p-20 bg-slate-800 rounded-xl w-full">
                        <div class="spinner-32 mx-auto"></div>
                    </div>

                    <!-- Empty State -->
                    <div v-else-if="playWallets.length === 0" class="flex flex-col items-center justify-center p-20 bg-slate-800 rounded-xl w-full">
                        <img src="@/assets/set/box-li.svg" class="w-20 h-20 mx-auto mb-3">
                        <div class="text-slate-400 text-center">No wallets found</div>
                        <button class="b3 mt-5 bc " @click="handleCreateWallet()"><img src="@/assets/set/plus-b.svg" class="w-4 h-4 mr-2">New Wallet</button>
                    </div>

                    <!-- Wallet List -->
                    <div v-else v-for="wallet in playWallets" 
                         :key="wallet.wallet_id"
                         @click="selectWallet(wallet)"
                         class="flex flex-row gap-2 justify-between items-center w-full bg-slate-800 rounded-xl px-4 py-3 cursor-pointer hover:bg-slate-700"
                         :class="{'bg-slate-700': selectedWallet?.wallet_id === wallet.wallet_id}">
                        <div class="w-2/3 flex flex-col gap-1">
                            <div class="c2">{{ wallet.wallet_name }}</div>
                            <div class="font-mono c1">{{ formatWallet(wallet.wallet_address, 6) }}</div>
                        </div>
                        <div class="w-1/3 flex flex-col gap-2 text-right">
                            <div class="font-mono text-sm c2">{{ formatSol(wallet.wallet_balance?.sol,4) }} SOL</div>
                            <div class="text-sm text-slate-400">~${{ formatSolToUsd(wallet.wallet_balance?.sol) }}</div>
                        </div>
                    </div>
                
                </div>

            </div>

        </div>
    </div>

    <!-- Fixed Footer -->
    <div v-if="playWallets.length > 0" class="bg-slate-900 px-4 py-3 flex justify-between border-t border-slate-700 rounded-b-lg">
        <button v-if="!isCreating" class="b3 ba" @click="handleCreateWallet()"><img src="@/assets/set/plus-g.svg" class="w-4 h-4 mr-2">Create Wallet</button>
        <div v-else class="spinner-32 mx-auto"></div>
        <router-link :to="{ name: 'profile_wallets' }" class="b3 bb" @click="close()">Manage Wallets</router-link>
    </div>
     

</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {

    data() {
        return {
            isCreating: false,
            error: null,
        }
    },
    computed: {
        ...mapState('playwallet', ['playWallets', 'selectedWallet', 'isWalletsLoading']),
        ...mapState('ws', ['price'])
    },
    methods: {
        ...mapActions('playwallet', ['fetchWallets', 'createWallet']),

        async handleCreateWallet() {
            try {
                // alert('create walelt from list');
                this.isCreating = true;
                await this.createWallet();
                // Wallet list will be automatically refreshed after creation ---
                // await this.fetchWallets(); is done in createWallet
                // alert ('new wallet created from the list going to deposit');
                this.$parent.view = 'deposit'; // Show deposit view after wallet creation
                
            } catch (error) {
                console.error('Failed to create wallet:', error);
                this.error = error.message+' Please try again later.';
                // You might want to show an error notification here
            } finally {
                this.isCreating = false;
            }
        },

        close() {
            this.$emit('hideWallet')
        },

        selectWallet(wallet) {
            this.$store.commit('playwallet/setSelectedWallet', wallet);
            this.$emit('toggleView', 'home');
        },

        toggleView(view) {
            this.$emit('toggleView', view);
        },

        chooseWallet(wallet) {
            this.$emit('chooseWallet', wallet);
        },
        
        // formatUsdValue(solBalance) {
        //     const sol = parseFloat(solBalance || '0');
        //     const solPrice = parseFloat(this.price?.sol_price || '0');
        //     const usdValue = sol * solPrice;
        //     return usdValue.toFixed(2);
        // }
    }
}

</script>