import { Buffer } from 'buffer';
import { Connection, PublicKey, Transaction, SystemProgram, clusterApiUrl, LAMPORTS_PER_SOL } from '@solana/web3.js';
import bs58 from 'bs58';
import * as nacl from 'tweetnacl';
import api from '../../api';

if (typeof window !== 'undefined') {
    window.Buffer = window.Buffer || Buffer;
}

const state = {
    isConnected: false,
    isSigning: false,
    publicKey: null,
    isInstalled: false,
};

const mutations = {

    SET_CONNECTED(state, isConnected) {
        state.isConnected = isConnected;
    },
    SET_SIGNING(state, isSigning) {
        state.isSigning = isSigning;
    },
    SET_WALLET_ADDR(state, publicKey) {
        state.publicKey = publicKey;
        this.commit('wallets/SET_WALLET_ADDR', publicKey, { root: true });
    },
    SET_INSTALLED(state, isInstalled) {
        state.isInstalled = isInstalled;
    },

};

// Helper functions at the top level (outside of actions)
const encryptPayload = (payload, sharedSecret) => {
    if (!sharedSecret) {
        throw new Error("missing shared secret");
    }
    const nonce = nacl.randomBytes(24);
    const encryptedPayload = nacl.box.after(
        new TextEncoder().encode(JSON.stringify(payload)), // We use TextEncoder instead of Buffer for browser
        nonce,
        sharedSecret
    );
    return [nonce, encryptedPayload];
};

const decryptPayload = (data, nonce, sharedSecret) => {
    if (!sharedSecret) {
        throw new Error('missing shared secret');
    }

    const decryptedData = nacl.box.open.after(
        bs58.decode(data),
        bs58.decode(nonce),
        sharedSecret
    );

    if (!decryptedData) {
        throw new Error('Unable to decrypt data');
    }

    return JSON.parse(new TextDecoder().decode(decryptedData));
};

const getSolanaBlockhash = async () => {
    const response = await api.post('user/tools/solana/blockhash', {
        data: {
            wallet_type: 'phantom',
        }
    });
    if(response.data?.data?.blockhash) {
        return response.data.data.blockhash;
    } else {
        return null;
    }
};

const actions = {

    // provider.on('connect', this.handlePhantomConnect);
    // provider.on('disconnect', this.handleWalletDisconnect);
    // provider.on('accountChanged', this.handlePhantomAccountChanged);

    async init({ commit, dispatch }) {
        // console.log('Store - Init phantom dispatched');
        const isInstalled = await dispatch('checkIfInstalled');
        commit('SET_INSTALLED', isInstalled);
        if (isInstalled) {
            const provider = await dispatch('getProvider');
            if(provider) {
                provider.on('connect', (publicKey) => {
                    console.log(`Phantom Event - connect + ${publicKey.toString()}`);
                    commit('SET_CONNECTED', true);
                    commit('SET_WALLET_ADDR', publicKey.toString());
                });
                provider.on('disconnect', () => {
                    console.log(`Phantom Event - disconnect`);
                    commit('SET_CONNECTED', false);
                    commit('SET_WALLET_ADDR', null);
                });
                provider.on('accountChanged', (publicKey) => {
                    if (publicKey) {
                        console.log(`Phantom Event - account changed to ${publicKey.toBase58()}`);
                        commit('SET_WALLET_ADDR', publicKey.toString());
                    } else {
                        console.error(`Phantom Event - account changed but no wallet address`);
                        // Attempt to reconnect to Phantom
                        // provider.connect().catch((error) => {
                        // ...
                        // });
                        commit('SET_CONNECTED', false);
                        commit('SET_WALLET_ADDR', null);
                    }
                });
            }
        }


    },

    async connect({ commit, dispatch }, { justAdd = false, justConnect = false }) {

        // The recommended and easiest way to connect to Phantom is by calling window.phantom.solana.connect()
        // If a phantom object exists, Solana apps can interact with Phantom via the API found at window.phantom.solana. 
        // This solana object is also available at window.solana to support legacy integrations.
        
        // if (!state.isInstalled) {
        //     throw new Error('Phantom wallet is not installed.');
        // }
        const provider = await dispatch('getProvider');
        if (!provider) {
            throw new Error('Phantom wallet is not installed');
        }
        try {

            // const provider = await dispatch('getProvider');
            // if (!provider.isConnected) {
            //     await provider.connect();
            //     if (provider.isConnected) {
            //         this.walletAddress = provider.publicKey.toString();
            //         // await this.getWalletInfo();
            //         await this.signMessage();
            //     }
            // }

            await provider.connect();
            
            if (provider.isConnected) {

                if(justConnect) {
                    dispatch('showNotification', { 
                        message: 'Phantom wallet connected successfully', 
                        type: 'success' 
                    }, { root: true });
                   
                } else {

                    const walletType = 'sol';
                    const walletNetwork = 2; //'solana';
                    const walletProvider = 'phantom';

                    let methodInit = 'authWalletSignInit';
                    let methodVerify = 'authWalletSignVerify';
                    if(justAdd) {
                        methodInit = 'authWalletSetInit';
                        methodVerify = 'authWalletSetVerify';
                    }

                    const publicKey = provider.publicKey.toString();
                    commit('SET_WALLET_ADDR', publicKey);
                    commit('SET_CONNECTED', true);
                    commit('SET_SIGNING', true);

                    // Get the message to sign from the backend
                    const message = await dispatch(`${methodInit}`, {
                        walletAddress: publicKey,
                        walletType,
                        walletNetwork,
                        walletProvider
                    }, { root: true });
        
                    const signedMessage = await dispatch('signMessage', message);

                    // Authenticate with backend
                    await dispatch(`${methodVerify}`, {
                        walletAddress: publicKey,
                        signedMessage,
                        walletType,
                        walletNetwork,
                        walletProvider
                    }, { root: true });

                    commit('SET_SIGNING', false);

                    dispatch('showNotification', { 
                        message: 'Phantom wallet connected successfully', 
                        type: 'success' 
                    }, { root: true });
                }

            }
        } catch (err) {
            console.error('Phantom connection error:', err);
            dispatch('showNotification', { 
                message: `Failed to connect Phantom wallet: ${err.message}`, 
                type: 'error' 
            }, { root: true });
            throw err;
        }
    },

    async disconnect({ commit, dispatch }) {
        const provider = await dispatch('getProvider');
        if (provider.isConnected) {
            // alert('provider connected');
            await provider.disconnect();
            commit('SET_CONNECTED', false);
            commit('SET_WALLET_ADDR', null);
            dispatch('showNotification', { 
                message: 'Phantom wallet disconnected', 
                type: 'success' 
            }, { root: true });
        }
    },

    async signMessage({ dispatch }, message) {
        const provider = await dispatch('getProvider');
        if (!provider || !provider.isConnected) {
            throw new Error('Phantom wallet is not connected');
        }
        const encodedMessage = new TextEncoder().encode(message);
        const signedMessage = await provider.signMessage(encodedMessage, 'utf8');
        return signedMessage;
    },

    ifPhantomInstalled() {
        // const isPhantomInstalled = window.phantom?.solana?.isPhantom
        // if not - https://phantom.app/
        // return 'solana' in window && window.solana.isPhantom;
        return 'phantom' in window || 'solana' in window;
    },

    async checkIfInstalled() {
        // alert('checkIfInstalled = '+typeof window);
        // return dispatch('ifPhantomInstalled');
        // const provider = await dispatch('getProvider');
        // if(provider) {
        //     return true;
        // } else {
        //     return false;
        // }
        // return 'phantom' in window || 'solana' in window;
        return new Promise((resolve) => {
            if (typeof window !== 'undefined') {
                resolve('phantom' in window || 'solana' in window);
            } else {
                resolve(false);
            }
        });
    },

    async getProvider() {
        return new Promise((resolve) => {
            if (typeof window !== 'undefined') {
                resolve(window.phantom?.solana || window.solana);
            } else {
                resolve(null);
            }
        });
    },

    async signTransaction({ dispatch }, { address, amount }) {
        const provider = await dispatch('getProvider');
        if (!provider || !provider.isConnected) {
            throw new Error('Phantom wallet is not connected');
        }

        try {
            
            // Create a simple transfer transaction with proper instruction
            const transaction = new Transaction();

            // const fromPubkey = new PublicKey(provider.value.publicKey.toString());

            // alert('fromPubkey='+provider.publicKey);
            
            // Add transfer instruction
            transaction.add(
                SystemProgram.transfer({
                    fromPubkey: provider.publicKey, // provider.publicKey,
                    toPubkey: new PublicKey(address),
                    lamports: amount, // 0.01 * 1e9, // 0.01 SOL
                })
            );

            // Get recent blockhash
            const blockhash = await getSolanaBlockhash();
            if (!blockhash) {
                throw new Error('Failed to get recent blockhash');
            }
            
            transaction.recentBlockhash = blockhash;
            transaction.feePayer = provider.publicKey;

            // Request signature from user via Phantom
            const { signature } = await provider.signAndSendTransaction(transaction, {
                skipPreflight: false,
                preflightCommitment: 'confirmed',
                maxRetries: 5,
            });

            // Wait for confirmation
            // await connection.confirmTransaction(signature, 'processed');
        
            // VERSIONED TRANSACTION //

            // also not showing up good
            // // Create transfer instruction
            // const transferInstruction = SystemProgram.transfer({
            //     fromPubkey: provider.publicKey,
            //     toPubkey: new PublicKey(address),
            //     lamports: amount,
            // });

            // // Get recent blockhash
            // const blockhash = await getSolanaBlockhash();
            // if (!blockhash) {
            //     throw new Error('Failed to get recent blockhash');
            // }

            // // Create v0 compatible message
            // const messageV0 = new TransactionMessage({
            //     payerKey: provider.publicKey,
            //     recentBlockhash: blockhash,
            //     instructions: [transferInstruction],
            // }).compileToV0Message();

            // // Create versioned transaction
            // const transaction = new VersionedTransaction(messageV0);

            // // Request signature from user via Phantom
            // const { signature } = await provider.signAndSendTransaction(transaction, {
            //     skipPreflight: false,
            //     preflightCommitment: 'confirmed',
            //     maxRetries: 5,
            // });
            
            return signature;

        } catch (error) {
            console.error('Phantom transaction error:', error);
            throw new Error(`Error sending transaction: ${error.message}`);
        }
    },

    // getPhantomProvider() {
    //     if ('phantom' in window) {
    //         const provider = window.phantom?.solana;
    //         if (provider?.isPhantom) {
    //             return provider;
    //         }
    //     }
    //     if ('solana' in window) {
    //         return window.solana;
    //     }
    //     return null;
    // },

    removeListeners({ dispatch }) {
        dispatch('getProvider').then(provider => {
            if (provider) {
                provider.removeAllListeners('connect');
                provider.removeAllListeners('disconnect');
                provider.removeAllListeners('accountChanged');
            }
        });
    },

    //* DeepLink to connect wallet on mobile *//

    async connectDeeplink({ commit, dispatch, rootState }, { mode = 'auth' }) {
        try {
            // Generate encryption key pair for Diffie-Hellman key exchange connection with Phantom
            const keyPair = nacl.box.keyPair();
            const dappPublicKey = keyPair.publicKey;
            const dappPrivateKey = keyPair.secretKey;

            // Use sessionToken from root state
            const sessionToken = rootState.sessionToken;
            // alert('sessionToken = '+sessionToken);

            // Store keypair and session data in backend
            await api.post('auth/wallet/deeplink/prep', {
                data: {
                    wallet_type: 'phantom',
                    session: sessionToken,
                    dapp_public_key: bs58.encode(dappPublicKey),
                    dapp_private_key: bs58.encode(dappPrivateKey),
                    mode: mode
                }
            });

            // Define parameters
            const appUrl = encodeURIComponent(window.location.origin);
            const redirectLink = encodeURIComponent(`${window.location.origin}/auth/phantom?app_session=${sessionToken}`);
            const cluster = 'mainnet-beta';

            // Construct deeplink URL
            const deeplinkUrl = `https://phantom.app/ul/v1/connect?` +
                `app_url=${appUrl}&` +
                `dapp_encryption_public_key=${bs58.encode(dappPublicKey)}&` +
                `redirect_link=${redirectLink}&` +
                `cluster=${cluster}`;

            console.log('Opening deeplink URL:', deeplinkUrl);

            // Open Phantom app via deeplink
            window.location.href = deeplinkUrl;

        } catch (err) {
            console.error('Phantom deeplink connection error:', err);
            throw err;
        }
    },

    async handleConnectResponse({ commit, dispatch, rootState }, { phantom_encryption_public_key, data, nonce, session }) {
        try {
            // console.log('Handling connect response with:', {
            //     phantom_encryption_public_key,
            //     data,
            //     nonce,
            //     session
            // });

            // Update session token in the local storage and load its data to the app
            if(session!=rootState.sessionToken) {
                // alert('set settion to '+session);
                dispatch('loadSession', { sessionToken: session, sessionData: {} }, { root: true });
                // commit('SET_SESSION_TOKEN', sessionToken);
            }
            
            // Get stored keypair from backend
            const response = await api.post('auth/wallet/deeplink/load', {
                data: { session } // user session reveived from deeplink after redirect from phantom, 
                // effectively sessionToken of the previous session where the user was goin to deeplink
            });

            // console.log('Backend response data:', response.data);

            if (!response.data?.data?.dapp_private_key) {
                throw new Error('No session data found. Please try connecting again.');
            }

            try {
                // Log input values before decoding
                // console.log('Decoding inputs:', {
                //     phantom_key: phantom_encryption_public_key,
                //     dapp_private: response.data.data.dapp_private_key
                // });
                // alert('phantom_key='+phantom_encryption_public_key+' ============== dapp_private='+response.data.data.dapp_private_key);

                const decodedPhantomKey = bs58.decode(phantom_encryption_public_key);
                const decodedPrivateKey = bs58.decode(response.data.data.dapp_private_key);

                // Verify key formats
                if (decodedPhantomKey.length !== 32) {
                    throw new Error(`Invalid phantom public key length: ${decodedPhantomKey.length}`);
                }
                if (decodedPrivateKey.length !== 32) {
                    throw new Error(`Invalid dapp private key length: ${decodedPrivateKey.length}`);
                }

                // // Log the raw key values
                // console.log('Decoded keys raw values:');
                // console.log('phantomKey:', Array.from(decodedPhantomKey).join(', '));
                // console.log('privateKey:', Array.from(decodedPrivateKey).join(', '));
                // alert('decodedPhantomKey='+decodedPhantomKey+' ============== decodedPrivateKey='+decodedPrivateKey);

                // // Log data types and lengths
                // console.log('Data type checks:', {
                //     phantomKey: decodedPhantomKey instanceof Uint8Array,
                //     privateKey: decodedPrivateKey instanceof Uint8Array,
                //     data: bs58.decode(data) instanceof Uint8Array,
                //     nonce: bs58.decode(nonce) instanceof Uint8Array
                // });
                
                // alert('decodedPhantomKey='+decodedPhantomKey)
                // Generate shared secret with swapped order
                const sharedSecretDH = nacl.box.before(
                    decodedPhantomKey,     // Phantom's public key first
                    decodedPrivateKey      // Our private key second
                );

                // console.log('Shared secret type:', sharedSecretDH instanceof Uint8Array);

                // // Also let's log the raw decoded data and nonce
                // console.log('Raw decoded data:', Array.from(bs58.decode(data)).join(', '));
                // console.log('Raw decoded nonce:', Array.from(bs58.decode(nonce)).join(', '));

                // // Log decryption inputs
                // console.log('Decryption inputs:', {
                //     encodedData: data,
                //     encodedNonce: nonce,
                //     decodedData: Array.from(bs58.decode(data)),
                //     decodedNonce: Array.from(bs58.decode(nonce))
                // });
                // alert('decodedData='+bs58.decode(data)+' ============== decodedNonce='+bs58.decode(nonce)); // returens null

                // Verify nonce length
                const decodedNonce = bs58.decode(nonce);
                if (decodedNonce.length !== 24) {
                    throw new Error(`Invalid nonce length: ${decodedNonce.length}`);
                }
                // alert('decodedNonce='+decodedNonce);

                // Attempt decryption with explicit type conversions
                const decryptedData = nacl.box.open.after(
                    new Uint8Array(bs58.decode(data)),
                    new Uint8Array(bs58.decode(nonce)),
                    new Uint8Array(sharedSecretDH)
                );
                // alert('decryptedData='+decryptedData);

                if (!decryptedData) {
                    throw new Error('Decryption returned null - invalid data or keys');
                }

                // Parse the decrypted data using TextDecoder instead of Buffer
                const decodedText = new TextDecoder().decode(decryptedData);
                // console.log('Decoded text before parsing:', decodedText);
                
                const parsedData = JSON.parse(decodedText);
                // console.log('Successfully decrypted and parsed data:', parsedData);

                // alert('parsedData='+JSON.stringify(parsedData));
                // parsedData contains only public_key and session

                const public_key = parsedData.public_key;
                const phantomSession = parsedData.session;

                // alert('public_key='+public_key+' ============== phantomSession='+phantomSession);
                // Store session data in backend
                await api.post('auth/wallet/deeplink/update', {
                    data: {
                        session,
                        public_key, // user wallet address
                        phantom_session: phantomSession,
                        shared_secret: bs58.encode(sharedSecretDH)
                    }
                });

                // Set wallet state
                commit('SET_CONNECTED', true);
                commit('SET_WALLET_ADDR', public_key);

                // Get message to sign from backend
                const mode = response.data.data.mode;
                // alert('mode='+mode);

                const methodInit = mode === 'auth' ? 'authWalletSignInit' : 'authWalletSetInit'; // 'connect' for settings or payment
                // alert('methodInit='+methodInit);
                const message = await dispatch(methodInit, {
                    walletAddress: public_key,
                    walletType: 'sol',
                    walletNetwork: 2,
                    walletProvider: 'phantom'
                }, { root: true });

                // Sign the message
                await dispatch('signMessageDeeplink', { message, phantomSession, sharedSecretDH, session });

            } catch (decodeError) {

                console.error('Detailed decryption error:', {
                    name: decodeError.name,
                    message: decodeError.message,
                    stack: decodeError.stack
                });
                throw new Error(`Decryption failed: ${decodeError.message}`);
            }

        } catch (error) {
            console.error('Connection response error:', {
                name: error.name,
                message: error.message,
                stack: error.stack
            });
            throw error;
        }
    },

    async signMessageDeeplink({ dispatch, rootState }, { message, phantomSession, sharedSecretDH = null, session = null }) {
        try {
            const sessionToken = session || rootState.sessionToken;
            
            // Get shared secret and dapp key from backend
            const response = await api.post('auth/wallet/deeplink/load', {
                data: { session: sessionToken } 
            });

            if (response.data?.result !== 'success') {
                throw new Error(response.data?.data?.message || 'Failed to load wallet data');
            }

            if (!response.data.data.dapp_public_key) {
                throw new Error('Missing dapp public key');
            }
            
            // Get shared secret if not provided
            let sharedSecret;
            if (sharedSecretDH instanceof Uint8Array) {
                sharedSecret = sharedSecretDH;
            } else if (typeof sharedSecretDH === 'string') {
                sharedSecret = bs58.decode(sharedSecretDH);
            } else if (response.data.data.shared_secret) {
                sharedSecret = bs58.decode(response.data.data.shared_secret);
            } else {
                throw new Error('Missing shared secret');
            }

            // Create the message payload
            const payload = {
                message: bs58.encode(new TextEncoder().encode(message)),
                session: phantomSession,
                display: 'utf8'
            };

            // Use the helper function directly, not through this
            const [nonce, encryptedPayload] = encryptPayload(payload, sharedSecret);

            // Create redirect link without extra encoding
            const redirectLink = `${window.location.origin}/auth/phantom?app_session=${sessionToken}`;

            // URLSearchParams will handle the encoding
            const params = new URLSearchParams({
                dapp_encryption_public_key: response.data.data.dapp_public_key,
                nonce: bs58.encode(nonce),
                redirect_link: redirectLink,
                payload: bs58.encode(encryptedPayload)
            });

            // Build URL like the example
            const url = `https://phantom.app/ul/v1/signMessage?${params.toString()}`;
            window.location.href = url;

        } catch (error) {
            console.error('Phantom deeplink sign message error:', error);
            throw error;
        }
    },

    async handleSignMessageResponse({ dispatch, rootState }, { data, nonce, session }) {
        try {

            // Update session token in the local storage and load its data to the app
            if(session != rootState.sessionToken) {
                // alert('set session to ' + session);
                dispatch('loadSession', { sessionToken: session, sessionData: {} }, { root: true });
                // commit('SET_SESSION_TOKEN', sessionToken);
            }
            
            // Get stored keypair from backend
            const response = await api.post('auth/wallet/deeplink/load', {
                data: { session }
            });

            if(response.data?.result != 'success') {
                throw new Error('Error loading wallet data: ' + response.data?.message);
            }

            if (!response.data.data?.shared_secret) {
                throw new Error('Wrong signature');
            }

            // Get data from saved session on backend
            const sharedSecret = bs58.decode(response.data.data?.shared_secret);
            const mode = response.data.data?.mode;
            const publicKey = response.data.data?.dapp_public_key;
            const wallet_address = response.data.data?.wallet_address;

            if (!sharedSecret || !mode || !publicKey) {
                throw new Error('Missing required data from backend');
            }

            // Decrypt the signature data using our helper function
            const signMessageData = decryptPayload(data, nonce, sharedSecret);
            
            // Log the decrypted data
            // alert('Decrypted signature data: ' + JSON.stringify(signMessageData, null, 2));

            // Extract signature from decrypted data
            const { signature } = signMessageData;
            if (!signature) {
                throw new Error('No signature found in decrypted data');
            }

            // Convert base58 signature string to Uint8Array
            const signatureBytes = bs58.decode(signature);

            // Format signature object to match what authWalletSignVerify expects
            const signedMessage = {
                signature: {  // Wrap in .signature property since authWalletSignVerify uses signedMessage.signature
                    type: "Buffer",
                    data: Array.from(signatureBytes)
                }
            };

            // Verify signature with backend based on mode
            const methodVerify = mode === 'auth' ? 'authWalletSignVerify' : 'authWalletSetVerify';

            await dispatch(methodVerify, {
                walletAddress: wallet_address,
                signedMessage,  // This will be processed by authWalletSignVerify to extract .signature
                walletType: 'sol',
                walletNetwork: 2,
                walletProvider: 'phantom'
            }, { root: true });

        } catch (error) {
            console.error('Error handling sign message response:', error);
            throw error;
        }
    },

    async signTransactionDeeplink({ dispatch, rootState }, { address, amount }) {
        try {
            // Get stored session data from backend
            const response = await api.post('auth/wallet/deeplink/load', {
                data: { session: rootState.sessionToken }
            });
    
            if (!response.data?.data?.phantom_session || !response.data.data?.shared_secret) {
                // If no session exists, we need to connect first
                await dispatch('connectDeeplink', { mode: 'pay' });
                return; // The connection flow will redirect and handle the rest
            }
    
            // Create the transaction
            const transaction = new Transaction();
            
            // Add transfer instruction
            transaction.add(
                SystemProgram.transfer({
                    fromPubkey: new PublicKey(response.data.data.wallet_address),
                    toPubkey: new PublicKey(address),
                    lamports: amount
                })
            );
    
            // Get recent blockhash
            const blockhash = await getSolanaBlockhash();
            if (!blockhash) {
                throw new Error('Failed to get recent blockhash');
            }
            
            transaction.recentBlockhash = blockhash;
            transaction.feePayer = new PublicKey(response.data.data.wallet_address);
    
            // Serialize the transaction
            const serializedTransaction = bs58.encode(
                transaction.serialize({
                    requireAllSignatures: false,
                })
            );
    
            // Create the payload
            const payload = {
                session: response.data.data.phantom_session,
                transaction: serializedTransaction,
            };
    
            // Get shared secret from backend
            const sharedSecret = bs58.decode(response.data.data.shared_secret);
    
            // Encrypt the payload
            const [nonce, encryptedPayload] = encryptPayload(payload, sharedSecret);
    
            // Create redirect link
            const redirectLink = `${window.location.origin}/auth/phantom?app_session=${rootState.sessionToken}`;
    
            // Build URL parameters
            const params = new URLSearchParams({
                dapp_encryption_public_key: response.data.data.dapp_public_key,
                nonce: bs58.encode(nonce),
                redirect_link: encodeURIComponent(redirectLink),
                payload: bs58.encode(encryptedPayload)
            });
    
            // Construct and open the deeplink URL
            const url = `https://phantom.app/ul/v1/signTransaction?${params.toString()}`;
            window.location.href = url;
    
        } catch (error) {
            console.error('Phantom transaction deeplink error:', error);
            throw error;
        }
    },

    async handleSignTransactionResponse({ dispatch, rootState }, { data, nonce, session }) {
        try {
            // Update session if needed
            if(session != rootState.sessionToken) {
                dispatch('loadSession', { sessionToken: session, sessionData: {} }, { root: true });
            }
            
            // Get stored session data
            const response = await api.post('auth/wallet/deeplink/load', {
                data: { session }
            });
    
            if(response.data?.result !== 'success') {
                throw new Error('Error loading wallet data: ' + response.data?.message);
            }
    
            // Get shared secret
            const sharedSecret = bs58.decode(response.data.data?.shared_secret);
            
            // Decrypt the signature data
            const transactionData = decryptPayload(data, nonce, sharedSecret);
            
            // Extract signed transaction
            const { transaction } = transactionData;
            if (!transaction) {
                throw new Error('No transaction found in decrypted data');
            }
    
            // Submit the signed transaction to Solana
            const connection = new Connection(clusterApiUrl('mainnet-beta'), 'confirmed');
            const signedTransaction = Transaction.from(bs58.decode(transaction));
            
            const signature = await connection.sendRawTransaction(
                signedTransaction.serialize(),
                { skipPreflight: false, preflightCommitment: 'confirmed' }
            );
    
            return signature;
    
        } catch (error) {
            console.error('Error handling sign transaction response:', error);
            throw error;
        }
    },

};

export default { namespaced: true, state, mutations, actions };