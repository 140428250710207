import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

// helper to check if a route is a docs route
// const isRouteDocs = (routeName) => {
//     return routeName === 'docs' || 
//            (routeName && routeName.startsWith('docs_'));
// };

const routes = [
    
  { path: '/', name: 'dash', component: () => import('@/pages/Dash.vue') },

  { path: '/coins', name: 'list', component: () => import('@/pages/Coins.vue'), props: true },
  { path: '/coin/:contract', name: 'coin', component: () => import('@/pages/Coin.vue'), props: true },
  { path: '/wait/:coinId', name: 'wait', component: () => import('@/pages/CoinWait.vue'), props: true },
  { path: '/trends', name: 'trends', component: () => import('@/pages/Trends.vue'), props: true },

  { path: '/portfolio', name: 'portfolio', component: () => import('@/pages/Portfolio.vue'), props: true, children: [
    { path: 'assets', name: 'portfolio_assets', component: () => import('@/pages/Portfolio/Assets.vue'), props: route => ({ wallet: route.query.wallet }), },
    { path: 'positions', name: 'portfolio_positions', component: () => import('@/pages/Portfolio/Positions.vue'), props: true },
    { path: 'orders', name: 'portfolio_orders', component: () => import('@/pages/Portfolio/Orders.vue'), props: route => ({ wallet: route.query.wallet }), },
    { path: 'auto', name: 'portfolio_auto', component: () => import('@/pages/Portfolio/Automation.vue'), props: true },
    { path: 'notifications', name: 'notifications', component: () => import('@/pages/Portfolio/Notifications.vue'), props: true },
  ], redirect: { name: 'portfolio_assets' } },
  
  { path: '/launch', name: 'launch', component: () => import('@/pages/Launch.vue'), props: true },
  { path: '/start', name: 'start', component: () => import('@/pages/Start.vue'), props: true },
  { path: '/drafts', name: 'drafts', component: () => import('@/pages/StartDrafts.vue'), props: true },

  { path: '/my/:walletId?', name: 'my', component: () => import('@/pages/MyCoins.vue'), props: true },
  { path: '/my/coin/:coinId', name: 'my_coin', component: () => import('@/pages/MyCoin.vue'), props: true },
  

  { path: '/profile', name: 'profile', component: () => import('@/pages/Profile.vue'), props: true, children: [
    { path: 'details', name: 'profile_details', component: () => import('@/pages/Profile/Details.vue'), props: true },
    // TEMP !! // { path: 'upgrade', name: 'profile_upgrade', component: () => import('@/pages/Profile/Upgrade.vue'), props: true }, 
    { path: 'tools', name: 'profile_tools', component: () => import('@/pages/Profile/Tools.vue'), props: true },
    { path: 'wallets', name: 'profile_wallets', component: () => import('@/pages/Profile/Wallets.vue'), props: true },
    { path: 'settings', name: 'profile_settings', component: () => import('@/pages/Profile/Settings.vue'), props: true },
  ], redirect: { name: 'profile_details' } },
//   { path: '/drop', name: 'drop', component: () => import('@/pages/Drop.vue'), props: true },

  // http://localhost:8080/auth/tele?code=111
  { 
    path: '/auth/tele', 
    name: 'auth_telegram',
    component: () => import('@/pages/Dash.vue'),
    beforeEnter: (to, from, next) => {
        const code = to.query.code; // get code from query
        // if (code) {
        console.log('Telegram auth code received:', code);
        store.commit('modals/MODAL_AUTH_TELEGRAM_SHOW', { code });
        next('/'); // go to dashboad 
        // } 
    }
  },

  { path: '/auth/phantom', name: 'auth_phantom', 
    component: () => import('@/pages/Dash.vue'), props: true,
    beforeEnter: (to, from, next) => {
        // alert('query=' + JSON.stringify(to.query));
        store.commit('modals/MODAL_AUTH_PHANTOM_SHOW');
        // next('/'); // DO NOT ALLOW THAT - BREAKS THE DEEPLINK AUTH FLOW !!
    }
  },
  { path: '/auth/solflare', name: 'auth_solflare', 
    component: () => import('@/pages/Dash.vue'), props: true,
    beforeEnter: (to, from, next) => {
        // alert('query=' + JSON.stringify(to.query));
        store.commit('modals/MODAL_AUTH_SOLFLARE_SHOW');
        // next('/'); //  DO NOT ALLOW THAT - BREAKS THE DEEPLINK AUTH FLOW !!
    }
  },

  { path: '/invite', name: 'invite', component: () => import('@/pages/Invite.vue'), props: true },
  { path: '/invite/withdraw', name: 'invite_withdraw', component: () => import('@/pages/Invite/Withdraw.vue'), props: true },
  { path: '/invite/history', name: 'invite_history', component: () => import('@/pages/Invite/History.vue'), props: true },
//   { path: '/invite/fees', name: 'invite_fees', component: () => import('@/pages/Invite/Fees.vue'), props: true },
//   { path: '/invite/joined', name: 'invite_joined', component: () => import('@/pages/Invite/Joined.vue'), props: true },
//   { path: '/invite/points', name: 'invite_points', component: () => import('@/pages/Invite/Points.vue'), props: true },
//   { path: '/points', name: 'points', component: () => import('@/pages/Points.vue'), props: true }, // invitation history
  { path: '/docs', name: 'docs', component: () => import('@/pages/Docs.vue'), props: true, children: [
    { path: 'about', name: 'docs_about', component: () => import('@/pages/Docs/About.vue'), props: true },
    { path: 'agents', name: 'docs_agents', component: () => import('@/pages/Docs/Agents.vue'), props: true },
    { path: 'wallets', name: 'docs_wallets', component: () => import('@/pages/Docs/Wallets.vue'), props: true },
    { path: 'launch', name: 'docs_launch', component: () => import('@/pages/Docs/Launch.vue'), props: true },
    { path: 'boost', name: 'docs_boost', component: () => import('@/pages/Docs/Boost.vue'), props: true },
    { path: 'usecases', name: 'docs_usecases', component: () => import('@/pages/Docs/Usecases.vue'), props: true },
    { path: 'token', name: 'docs_token', component: () => import('@/pages/Docs/Token.vue'), props: true },
    { path: 'affiliate', name: 'docs_affiliate', component: () => import('@/pages/Docs/Affiliate.vue'), props: true },
    { path: 'access', name: 'docs_access', component: () => import('@/pages/Docs/Access.vue'), props: true },
    { path: 'market', name: 'docs_market', component: () => import('@/pages/Docs/Market.vue'), props: true },
    { path: 'bundle', name: 'docs_bundle', component: () => import('@/pages/Docs/Bundle.vue'), props: true },
    // { path: 'roadmap', name: 'docs_roadmap', component: () => import('@/pages/Docs/Roadmap.vue'), props: true },
    { path: 'telegram', name: 'docs_telegram', component: () => import('@/pages/Docs/Telegram.vue'), props: true },
    { path: 'content', name: 'docs_content', component: () => import('@/pages/Docs/Content.vue'), props: true },
    { path: 'trade', name: 'docs_trade', component: () => import('@/pages/Docs/Trade.vue'), props: true },
    // { path: 'levels', name: 'docs_levels', component: () => import('@/pages/Docs/Levels.vue'), props: true },
    { path: 'account', name: 'docs_account', component: () => import('@/pages/Docs/Account.vue'), props: true },
    { path: 'privacy', name: 'docs_privacy', component: () => import('@/pages/Docs/Privacy.vue'), props: true },
    { path: 'terms', name: 'docs_terms', component: () => import('@/pages/Docs/Terms.vue'), props: true },
    { path: 'feedback', name: 'docs_feedback', component: () => import('@/pages/Docs/Feedback.vue'), props: true },
  ], redirect: { name: 'docs_about' } },

//   { path: '/terms', name: 'terms', component: () => import('@/pages/Terms.vue'), props: true },
  { path: '/affiliate', name: 'affiliate', component: () => import('@/pages/Affiliate.vue'), props: true },
//   { path: '/history', name: 'history', component: () => import('@/pages/History.vue'), props: true },

  { path: '/test', name: 'test', component: () => import('@/pages/Test.vue'), props: true },

//   { path: 'coin/:contract', name: 'coin', component: () => import('@/pages/MyCoin/MyCoin.vue'), props: true,
//     children: [
//         { path: '', name: 'coin_info', component: () => import('@/pages/MyCoin/General.vue'), props: route => ({ coinId: route.params.coinId }) },
//         { path: 'liq', name: 'liq', component: () => import('@/pages/MyCoin/Trading.vue'), props: route => ({ coinId: route.params.coinId }) },
//         { path: 'soc', name: 'soc', component: () => import('@/pages/MyCoin/Community.vue'), props: route => ({ coinId: route.params.coinId }) },
//         // { path: 'all', name: 'all', component: () => import('@/pages/MyCoin/Allocation.vue'), props: route => ({ coinId: route.params.coinId }) },
//         { path: 'set', name: 'set', component: () => import('@/pages/MyCoin/Settings.vue'), props: route => ({ coinId: route.params.coinId }) },
//     ],
//     redirect: { name: 'gen' }
// },

  { 
    path: '/:pathMatch(.*)*', 
    name: 'not-found', 
    component: () => import('@/pages/NoPage.vue')
  }

//   { path: '/twitter', name: 'twitter', component: () => import('@/pages/TwitterCallback.vue'), props: true },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else if (to.hash) {
                const element = document.querySelector(to.hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            return { top: 0 } // return { x: 0, y: 0 }
        }
      },
  });

router.beforeEach((to, from, next) => {
  console.log('Navigation to:', to.name);
  
  // !! DO THAT BETTER:
  // Get auth state from store
  // const isAuthenticated = !!store.state.jwt;
  // Check if route is docs
  // const isDocsPage = isDocsRoute(to.name);
  // Allow access to docs pages without auth
  // if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated && !isDocsPage) {

  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next({ name: 'dash' });
  } else {
    next();
  }

});

export default router;