<template>

    <div class="fixed inset-0 bg-black backdrop-blur-sm bg-opacity-45 z-[800] flex flex-col h-full py-10 overflow-y-auto" @click="hideWelcome()">
    <!-- <div class="fixed inset-0 bg-slate-900 backdrop-blur-sm bg-opacity-65 z-[800] flex flex-col h-full py-10 overflow-y-auto" @click="hideWelcome()"> -->

    <div class="flex flex-col p-5 mk:p-8 gap-4 rounded-sm border border-white border-opacity-10 w-[80%] max-mi:w-[92%] max-w-[1200px] mx-auto md:my-auto
            shadow-xl shadow-slate-950 relative " @click.stop><!-- bg-opacity-10 bg-slate-200 -->

            <div class="bg-[url('@/assets/bg_glow.jpg')] bg-cover bg-slate-900 bg-opacity-60 max-md:bg-center opacity-80 inset-0 absolute z-[10] "></div>
            <!-- 
            <div class=" bg-slate-900 bg-opacity-90 inset-0 absolute z-[10]"></div>
            <div class="bg-[url('@/assets/bg.jpg')] bg-cover opacity-10 absolute inset-0  z-[11]"></div> 
            -->

            <div class="z-[20] text-lg">
                <div class="flex flex-row gap-5 mb-3">
                    <h2 class="pt-2 mb-2">Welcome to Airpump!</h2>
                    <div class="shrink-0 ml-auto"><button @click="hideWelcome()" class=" mr-1"><img src="@/assets/set/close.svg" class="w-5 h-5"></button></div>
                </div>
                <div class="mb-5 text-xl text-slate-200">

                    Discover coins to be launched on pump.fun and set limit orders in the first transaction bundle.
                    <!-- and Raydium -->
                    <!-- and schedule your own coin for a social engagement boost and pre-orders. -->
                     <!-- with ✨ AI  -->
                    <!-- <img src="@/assets/set/robot-g.svg" class="w-5 h-5 inline-block mb-2 mx-1"> Agent -->
                   
                    <!-- Snipe coins before they launch and when graduate on pump.fun,
                    schedule your coin launch with protection from MEV attacks and snipers. -->
                    <!-- Crypto trading terminal built for an era of AI Agents. -->
                    <!-- Launch your tokenized AI <img src="@/assets/set/robot-g.svg" class="w-5 h-5 inline-block mb-2 mx-1"> Agent
                    that will trade coins and other <img src="@/assets/set/robot-g.svg" class="w-5 h-5 inline-block mb-2 mx-1"> Agents,
                    and also launch its own coins and <img src="@/assets/set/robot-g.svg" class="w-5 h-5 inline-block mb-2 mx-1"> Agents. -->
                    <!-- launch their own coins and <img src="@/assets/set/robot-g.svg" class="w-5 h-5 inline-block mb-2 mx-1"> Agents. -->
                    <!--
                    Trading platform designed for autonomous AI <img src="@/assets/set/robot-g.svg" class="w-5 h-5 inline-block mb-2 mx-1"> agents, 
                    but fully accessible for humans to use as well.
                    -->
                    <!-- <img src="@/assets/set/shades-o.svg" class="w-5 h-5 inline-block ml-1"> -->
                    
                </div>
                <button class="b2 bn mb-5 md:hidden" @click="showAuth()">Sign In</button>
            
                <div class="flex flex-col md:flex-row gap-5 mk:gap-8">
                    <div class="md:w-1/3 bg-slate-950 rounded-xl p-5 bg-opacity-55 shadow-slate-950 shadow-sm border-slate-950 border border-opacity-10">
                        <div class="mb-4"><img src="@/assets/s5.svg" class="h-8"></div>
                        <h3 class="mb-2 c1">Filter Gems</h3>
                        <div class="text-lg">
                            <div class="mb-2">
                                Access pump.fun launch schedule
                                to
                                evaluate coins
                                before CA is revealed and
                                snipe  graduation to Pump Swap.
                                <!-- before they start  -->
                                <!-- on pump.fun -->
                                 <!-- and Raydium  -->
                                  <!-- front-runners. -->
                                <!-- on pump.fun and snipe the moment CA is revealed. -->
                                <!-- Snipe on launch and snipe them while migrating. -->
                                <!-- Explore and snipe coins the moment they launch on pump.fun -->
                            <!-- </div>
                            <div class="mb-2"> -->
                                <!-- Utilize on-chain data analytics and advanced filters to avoid rugs. -->
                                
                            </div>
                            <!-- <div class="c3 flex flex-row gap-4">
                                <img src="@/assets/set/robot-g.svg" class="w-5 h-5 inline-block mt-1">
                                <div>Appoint AI Agent to research and suggest coins.</div>
                            </div> -->
                            <!-- <div class="mb-2">
                                <ul class=" list-disc pl-5">
                                    <li class="pl-1">No liquidity required</li>
                                    <li class="pl-1">Fair launch (no dev/airdrop allocation)</li>
                                    <li class="pl-1">Safe auidited smart contracts</li>
                                    <li class="pl-1">No commitments or presales</li>
                                </ul>
                            </div> -->
                            <!-- <div class="c3 flex flex-row gap-4">
                                <img src="@/assets/set/robot-g.svg" class="w-5 h-5 inline-block mt-1">
                                <div>Ask an Agent for comprehensive analysis on a coin </div>
                            </div> -->
                            <!-- 
                            when enough people buy on the bonding curve it reaches a market cap of $69k
                            12k of liquidity is then deposited in raydium and burned 
                            -->
                        </div>
                    </div>

                    <div class="md:w-1/3 bg-slate-950 rounded-xl p-5 bg-opacity-55 shadow-slate-950 shadow-sm border-slate-950 border border-opacity-10 ">
                        <div class="mb-2"><img src="@/assets/s2.svg" class="h-10"></div>
                        <!-- <h3 class="mb-2 c1">Get In Fast</h3> -->
                        <h3 class="mb-2 c1">Flip Fast</h3>
                        <!-- <h3 class="mb-2 c1">Automate Trading</h3> -->
                        <div class="text-lg">
                            <div class="mb-2"> 
                                <!-- &mdash; -->
                                <!-- buy & sell  -->
                                Orders executed in the first bundle with coin creation transaction. 
                                No chance for MEV bots or front-runners to get in.
                                 <!-- Airpump eliminates insiders bundling early buys before public access, 
                                unlocking true fair launches 
                                by letting anyone reserve a slot in the first block.
                            -->
                                <!-- Get in before the chart moves &mdash; -->
                                <!-- Snipe the moment CA is revealed -->
                                <!-- Setup automated trading with limit orders, profit-taking and stop-loss strategies. -->
                                <!-- with blazing speeds and airborne precision. -->
                               <!-- Blazing speeds on DEXes with the precision 
                                of a centralized exchange.
                                Automate with limit orders, profit-taking, stop-loss strategies  -->
                                <!-- or let AI do it for you. -->

                                <!-- Trade on DEXes with convenience of centralized exchange using -->
                                <!-- sniping,  -->
                                <!-- automated limit orders, profit-taking and stop-loss strategies. -->
                                <!-- , just to name a few. -->
                            </div>
                            <!-- <div class="c3 flex flex-row gap-4">
                                <img src="@/assets/set/robot-g.svg" class="w-5 h-5 inline-block mt-1">
                                <div>Delegate your walelt to be managed by AI Agent 🙌</div>
                            </div> -->
                        </div>
                    </div>

                    <div class="md:w-1/3 bg-slate-950 rounded-xl p-5 bg-opacity-55 shadow-slate-950 shadow-sm border-slate-950 border border-opacity-10">
                        <div class="mb-4"><img src="@/assets/s3.svg" class="h-8"></div>
                        <h3 class="mb-2 c1">Plan Your Coin</h3>
                        <div class="mb-2">
                    

                            Schedule coin launch to get extra 
                            <!-- prior announcement and -->
                             social engagement,
                            <!-- on pump.fun or Raydium to -->
                             <!-- or Raydium, -->
                            <!-- give it extensive visibility, -->
                             build up community 
                            and collect pre-orders
                            before trading starts.

                            <!-- with powerful Aripump <img src="@/assets/set/fire.svg" class="w-5 h-5 inline-block mb-2 mx-1"> boost packages. -->
                            <!-- with a social engagement boost provided by the platform. -->
                            <!-- with advanced AI utility, -->
                            <!-- anti-MEV protection and
                            proper 
                     -->
                            <!-- that will
                            earn comissions managing portfolios
                            for managing wallets
                            to be distributed between token holders. -->
                            
                        </div>
                        <!-- <div class="c3 flex flex-row gap-4">
                            <img src="@/assets/set/robot-g.svg" class="w-5 h-5 inline-block mt-1">
                            <div>Watch your Agent launch new coins and its own Agents ✨</div>
                        </div> -->
                    </div>
                    
                </div>
                <div class="flex flex-row gap-5 mt-5 items-center">
                    <button class="b3 bc font-bold max-md:hidden" @click="showAuth()" v-if="!isAuth">Sign In</button>
                    <button class="b3 bc font-bold md:hidden" @click="hideWelcome()" v-if="!isAuth">Close</button>
                    <button class="b3 ba font-bold" @click="hideWelcome()" v-if="isAuth">Explore Platform</button>
                    <router-link :to="{ name: 'docs' }" class="b3 ba ml-auto"  @click.native="hideWelcome()">Read Docs</router-link>
                </div>

            </div>
        </div>
                
    </div>

</template>

<script>
import { mapActions } from 'vuex';

export default {
    methods: {

        ...mapActions('modals', ['showAuthModal', 'hideAuthModal']),

        showAuth() {
            this.showAuthModal();
            this.hideWelcome();
        },

        hideWelcome() {
            this.$emit('hideWelcome');
        },
        // startTour() {
        //     this.$emit('startTour');
        // }
    }
}
</script>