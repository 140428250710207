// import Web3 from 'web3';
// import { Connection, PublicKey } from '@solana/web3.js';
import { handleError } from '../utils/errors';
import { Connection, clusterApiUrl } from '@solana/web3.js';

// wallets: [
//     { name: 'Phantom', type: 'phantom' },
//     { name: 'MetaMask', type: 'metamask' },
//     { name: 'Solflare', type: 'solflare' },
//     { name: 'Torus', type: 'torus' },
//     { name: 'Ledger', type: 'ledger' },
//     { name: 'WalletConnect', type: 'walletconnect' }
// ],
// selectedNetwork: 'mainnet-beta',

const state = {
    isConnecting: false,
    isConnected: false,
    walletNetwork: 'solana', // or 'ethereum' for MetaMask
    walletAddr: null,
    walletType: '', // 'phantom', 'solflare', or 'metamask'
    walletData: null, // not used for solana
    error: null,
};
  
const mutations = {

    SET_CONNECTING(state, isConnecting) {
      state.isConnecting = isConnecting;
    },
    SET_CONNECTED(state, isConnected) {
        state.isConnected = isConnected;
    },
    SET_WALLET_ADDR(state, walletAddr) {
        state.walletAddr = walletAddr;
    },
    SET_WALLET_TYPE(state, walletType) {
      state.walletType = walletType;
    },
    SET_WALLET_DATA(state, walletData) {
      state.walletData = walletData;
    },
    SET_WALLET_NETWORK(state, walletNetwork) {
      state.walletNetwork = walletNetwork;
    },

};
  
const actions = {

    async connectWallet ({ commit, dispatch }, { walletType, mode = 'auth', justAdd = false, justConnect = false, deeplink = false }) {
        // alert('connectWallet '+walletType);
        // There the connection of the wallet begins
        // alert('justAdd '+justAdd);
        if (deeplink && (walletType === 'phantom' || walletType === 'solflare')) {
            return dispatch('connectWalletDeeplink', { walletType, mode });
        }

        commit('SET_CONNECTING', true);
        commit('SET_WALLET_TYPE', walletType);
        try {
            if(walletType === 'metamask') {
                await dispatch('metamask/connect', { justAdd, justConnect }, { root: true });
                commit('SET_CONNECTED', true);
                commit('SET_WALLET_NETWORK', 'ethereum');
            } else if(walletType === 'phantom') {
                await dispatch('phantom/connect', { justAdd, justConnect }, { root: true });
                commit('SET_CONNECTED', true);
                commit('SET_WALLET_NETWORK', 'solana');
            } else if(walletType === 'solflare') {
                await dispatch('solflare/connect', { justAdd, justConnect }, { root: true });
                commit('SET_CONNECTED', true);
                commit('SET_WALLET_NETWORK', 'solana');
            } 
            // else if (walletType === 'adapter') {
            //     await dispatch('adapter/connect', justAdd, { root: true });
            //     commit('SET_CONNECTED', true);
            //     commit('SET_WALLET_NETWORK', 'solana');
            // }
        } catch (err) {
            const errorMessage = handleError(err, '[STORE/wallets/connectWallet]');
            throw new Error(errorMessage);
            // dispatch('showNotification', { 
            //     message: `Connection error for ${walletType}: ${err.message}`, 
            //     type: 'error' 
            //   }, { root: true });
            // throw err;
        } finally {
            commit('SET_CONNECTING', false);
        }
    },

    // Triggered in connectWallet() above, or can be called directly from components
    async connectWalletDeeplink ({ commit, dispatch }, { walletType, mode = 'auth' }) {
        // alert('connectWalletDeeplink = '+walletType+' justadd = '+justAdd);
        commit('SET_CONNECTING', true);
        commit('SET_WALLET_TYPE', walletType);
        try {
            if(walletType === 'metamask') {
                await dispatch('metamask/connect', justAdd, { root: true });
                commit('SET_CONNECTED', true);
                commit('SET_WALLET_NETWORK', 'ethereum');
            } else if(walletType === 'phantom') {
                await dispatch('phantom/connectDeeplink', { mode }, { root: true });
                commit('SET_CONNECTED', true);
                commit('SET_WALLET_NETWORK', 'solana');
            } else if(walletType === 'solflare') {
                await dispatch('solflare/connectDeeplink', { mode }, { root: true });
                commit('SET_CONNECTED', true);
                commit('SET_WALLET_NETWORK', 'solana');
            } 
        } catch (err) {
            const errorMessage = handleError(err, '[STORE/wallets/connectWalletDeeplink]');
            throw new Error(errorMessage);
            // dispatch('showNotification', { 
            //     message: `Connection error for ${walletType}: ${err.message}`, 
            //     type: 'error' 
            //   }, { root: true });
            // throw err;
        } finally {
            commit('SET_CONNECTING', false);
        }
    },
  
    async disconnectWallet({ state, commit, dispatch }) {
        if (state.walletType === 'phantom') {
            await dispatch('phantom/disconnect', null, { root: true });
        } else if (state.walletType === 'solflare') {
            await dispatch('solflare/disconnect', null, { root: true });
        } else if (state.walletType === 'adapter') {
            await dispatch('adapter/disconnect', null, { root: true });
        } else if (state.walletType === 'metamask') {
            await dispatch('metamask/disconnect', null, { root: true });
        }
        commit('SET_CONNECTED', false);
        commit('SET_WALLET_TYPE', null);
        commit('SET_WALLET_NETWORK', null);
    },
  
    async signMessage({ state, dispatch }, message) {
        if (!state.walletType) {
            throw new Error('No wallet connected');
        }
        try {
            let signature;
            if (state.walletType === 'phantom') {
                signature = await dispatch('phantom/signMessage', message, { root: true });
            } else if (state.walletType === 'solflare') {
                signature = await dispatch('solflare/signMessage', message, { root: true });
            } else if (state.walletType === 'metamask') {
                signature = await dispatch('metamask/signMessage', message, { root: true });
            }
            return signature;
        } catch (err) {
            throw new Error(`Error signing message: ${err.message}`);
        }
    },

    async sendTX({ state, dispatch }, { walletType, address, amount, deeplink = false }) {
        if(deeplink) {
            return dispatch('sendTXDeeplink', { walletType, address, amount });
        }
        if (!state.isConnected) {
            throw new Error('Wallet not connected');
        }

        try {
            if (walletType === 'phantom') {
                return await dispatch('phantom/signTransaction', { address, amount }, { root: true });
            } else if (walletType === 'solflare') {
                return await dispatch('solflare/signTransaction', { address, amount }, { root: true });
            }
            throw new Error('Unsupported wallet type');
        } catch (err) {
            throw new Error(`Transaction failed: ${err.message}`);
        }
    },

    async sendTXDeeplink({ state, dispatch }, { walletType, address, amount }) {
        try {
            // Check if wallet is connected
            if (!state.isConnected) {
                // Connect wallet first
                await dispatch('connectWallet', {
                    walletType,
                    mode: 'pay',
                    justAdd: true,
                    deeplink: true
                });
                await dispatch('triggerWalletsRefresh');
            }
    
            // Now proceed with transaction
            if (walletType === 'phantom') {
                return await dispatch('phantom/signTransactionDeeplink', { 
                    address, 
                    amount 
                }, { root: true });
            } else if (walletType === 'solflare') {
                return await dispatch('solflare/signTransactionDeeplink', { 
                    address, 
                    amount 
                }, { root: true });
            }
            throw new Error('Unsupported wallet type');
        } catch (err) {
            throw new Error(`Transaction failed: ${err.message}`);
        }
    },

    async checkConnection({ dispatch, commit }) {
        // Check Phantom
        if (await dispatch('phantom/checkIfInstalled', null, { root: true })) {
            try {
                const phantomProvider = await dispatch('phantom/getProvider', null, { root: true });
                const resp = await phantomProvider.connect({ onlyIfTrusted: true });
                dispatch('showNotification', { 
                    message: `Phantom wallet connected`, 
                    type: 'success' 
                }, { root: true });
                commit('SET_CONNECTED', true);
                commit('SET_WALLET_TYPE', 'phantom');
                commit('SET_WALLET_NETWORK', 'solana');
                commit('phantom/SET_CONNECTED', true, { root: true });
                commit('phantom/SET_WALLET_ADDR', resp.publicKey.toString(), { root: true });
            } catch (err) {
                if (err.message === 'User rejected the request.') {
                    // Happens when no wallet have an active connection
                    console.log("User rejected the connection request");
                } else {
                    throw err;
                }
                // console.log("Phantom wallet not connected");
            }
        }
        // Check Solflare
        if (await dispatch('solflare/checkIfInstalled', null, { root: true })) {
            try {
                const solflareProvider = await dispatch('solflare/getProvider', null, { root: true });
                if (solflareProvider.isConnected) {
                    dispatch('showNotification', { 
                        message: `Solflare wallet connected`, 
                        type: 'success' 
                    }, { root: true });
                    commit('SET_CONNECTED', true);
                    commit('SET_WALLET_TYPE', 'solflare');
                    commit('SET_WALLET_NETWORK', 'solana');
                    commit('solflare/SET_CONNECTED', true, { root: true });
                    commit('solflare/SET_WALLET_ADDR', solflareProvider.publicKey.toString(), { root: true });
                }
            } catch (err) {
                console.log("Solflare wallet not connected");
            }
        }
        // Check MetaMask
        if (await dispatch('metamask/checkIfInstalled', null, { root: true })) {
            try {
                const provider = await dispatch('metamask/getProvider', null, { root: true });
                const accounts = await provider.request({ method: 'eth_accounts' });
                // if (accounts.length > 0) {
                if (provider.isConnected) {
                    // dispatch('showNotification', { 
                    //     message: `MetaMask wallet connected`, 
                    //     type: 'success' 
                    // }, { root: true });
                    commit('SET_CONNECTED', true);
                    commit('SET_WALLET_TYPE', 'metamask');
                    commit('SET_WALLET_NETWORK', 'ethereum');
                    // alert(JSON.stringify(accounts));
                    commit('metamask/SET_CONNECTED', true, { root: true });
                    commit('metamask/SET_WALLET_ADDR', accounts[0], { root: true });
                }
            } catch (err) {
                console.log("MetaMask wallet not connected");
            }
        }
    },

    async removeAllListeners({ dispatch }) {
        dispatch('phantom/removeListeners', null, { root: true });
        dispatch('solflare/removeListeners', null, { root: true });
        dispatch('metamask/removeListeners', null, { root: true });
    },

};

const getters = {

    // isAnyWalletConnected(state, getters, rootState) {
    //     return rootState.phantom.isConnected || rootState.solflare.isConnected;
    // },
    // connectedWalletType(state) {
    //     return state.walletType;
    // },
    // connectedPublicKey(state, getters, rootState) {
    //     if (state.walletType === 'phantom') {
    //         return rootState.phantom.publicKey;
    //     } else if (state.walletType === 'solflare') {
    //         return rootState.solflare.publicKey;
    //     }
    //     return null;
    // },

};
  
export default { namespaced: true, state, mutations, actions, getters };