<template>

    <div class="z-[700] group
    fixed bottom-0 left-0 
    md:w-14 max-md:w-full

    bgc md:border-r border-slate-700  
    md:flex md:flex-col

    md:opacity-65 hover:opacity-100
    max-md:bg-opacity-65

    max-md:hidden
    
    max-md:h-16 max-md:text-xs

    max-md:border-t max-md:border-slate-700 
    md:text-lg bg-[#091622] max-mk:w-[50px] 
    max-md:items-center max-md:text-center text-base max-md:menushadow max-md:uppercase
    
    md:hover:w-[180px] transition-all duration-200 hover:shadow-xl hover:shadow-slate-950
    
    overflow-hidden 
    " :class="showLiner ? 'md:top-8' : 'md:top-0'"
    >
    <!--max-md:text-sm-->

        <div class="group-hover:border-b border-slate-800 px-4 h-16 max-md:hidden ">
            <!-- h-[52px] -->
            <button @click="showWelcome()" class="flex flex-row gap-3 w-[200px] ml-0 pt-5">
                <img src="@/assets/air.svg" class="w-7 h-7 mr-1">
                <img src="@/assets/logo_text.png" class="h-[20px] mt-2">
            </button>
        </div>

        <div class="flex-grow">

        </div>
    
        <div class="p-3 flex flex-col gap-5">
            <router-link :to="{ name: 'docs' }"   :class="{ 'c1': isActiveTab('docs') }" class="flex flex-col md:flex-row gap-1 md:gap-4 md:w-fit max-md:hidden"><img src="@/assets/set/book.svg" class="w-7 h-7 mx-auto"><span class="">Docs</span></router-link>
        </div>

    </div>

</template>

<script>
import { mapState } from 'vuex';

export default {
    props: {
        showLiner: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState(['isAuth']),
        ...mapState(['userData']),
    },
    methods: {
        showWelcome() {
            this.$emit('showWelcome');
        }
    }
}
</script>