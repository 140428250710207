<template>

    <div @click="hideModal()" class="fixed inset-0 bg-black backdrop-blur-sm bg-opacity-45 z-[800] flex flex-col h-full py-8 overflow-y-auto">
    <!-- <div @click="hideModal()" class="fixed inset-0 bg-slate-900 backdrop-blur-sm bg-opacity-75 z-[800] flex flex-col h-full py-8 overflow-y-auto"> -->

        <div @click.stop class="bg-slate-800 md:p-6 p-4 shadow-xl shadow-slate-950
        rounded-sm border border-white border-opacity-10
        
         min-w-[320px] w-[80%] max-w-[500px] mx-auto my-auto ">
            <div class="flex flex-row justify-between">
                <h2 class="font-bold mb-3">Connect Telegram Bot</h2>
                <div @click="hideModal()" class="p2 cursor-pointer" ><img src="@/assets/set/close.svg" class="w-5 h-5"></div>
                <!-- border-slate-400 rounded-full -->
            </div>

            <div v-if="isLoading" class="flex flex-col justify-center gap-3 border border-slate-600 p-5 rounded-xl mb-4 text-center min-h-[250px]">
                <div class="spinner-32 mx-auto"></div>
                <h3 class="font-bold">Loading...</h3>
            </div>
            <div v-else>
                    
                <div class="mb-5 text-lg">
                    Add a Telegram bot to authenticate in your account and receive notifications.
                </div>
                
                <div class="flex flex-col gap-3 border border-slate-600 p-4 rounded-xl mb-4">
                    <h3 class="font-bold">Telegram</h3>

                    <div class="">Open the Telegram bot:</div>
                    <a v-if="telegramAuthLink" :href="`${telegramAuthLink}`" target="_blank" class="b3 bc w-fit">
                        <span>Open <img src="@/assets/social/t-b.svg" class="w-5 h-5 inline-block mb-1 mx-1"> Telegram</span>
                    </a>
                    <!-- <div class="text-xs text-slate-400">Use <a href="https://t.me/airpumpbot" target="_blank" class="underline">Telegram</a> messanger to authentificate.</div> -->
                    <div class="text-sm text-slate-400">Start the bot and confirm authorization by pressing the button.</div>
                    <!-- <div class="text-xs text-slate-400">Use Airpump as Telegram miniapp or comfirm authentification in the browser.</div> -->
                </div>
              
            </div>

        </div>

    </div> 

</template>

<script>
import { mapState, mapActions } from 'vuex';
import api from '@/api';

export default {
    name: 'Airpump - Sign In',
    data() {
        return {

            DEBUG: false,
            isLoading: false,
            signStep: 1,
            sendIn: 0,
            sendInNext: 30, // 30 sec
            resendTimer: null,
            isSubmit: false,
            signEmail: true,
            signWallet: false,
            signTelegram: false,
            telegramAuthLink: null,
            sessionCode: null, // for telegram to identify session

        }
    },
    computed: {
  
    },
    mounted() {
           // _${this.codeInvite || 'no'}_${this.utmSource || 'direct'}_${this.utmCampaign || 'no'}_${this.utmMedium || 'no'}`;
    },
    methods: {

        ...mapActions(['showNotification']),
        ...mapActions('playwallet', ['fetchWallets']),

        //* Telegram *//

        async initTelegram() { // Added resend parameter with default value false
            this.isSubmit = true;
            this.isLoading = true;
            try {
           
                const response = await api.post('user/settings/telegram/session');
                // alert(JSON.stringify(response.data));
                if(response.data.result=='success') {
                    this.sessionCode=response.data.session_code; // !! WARNING! Remove this!
                } else {
                    this.sessionCode=null;
                }

                this.telegramAuthLink = `https://t.me/${process.env.VUE_APP_TELEGRAM_BOT}?start=connect__${this.sessionCode || 'no'}__${this.codeInvite || 'no'}`;
                
            } catch (error) {
                console.error('Failed to fetch coins:', error);
                this.showNotification({ type: 'error', message: 'Failed to authorise: '+error });
                // Handle error (e.g., show notification)
            } finally {
                this.isSubmit = false;
                this.isLoading = false;
            }
        },

        //* Modal Control *//

        showConnect() {
            this.$emit('showConnect');
        },
        hideModal() {
            this.$emit('hideModal');
        },
        
    },
    beforeUnmount() {
        if (this.resendTimer) {
            clearInterval(this.resendTimer);
        }
    }
}
</script>
