import { Connection, PublicKey, Transaction, SystemProgram, clusterApiUrl } from '@solana/web3.js';
import * as bs58 from 'bs58';
import * as nacl from 'tweetnacl';
import api from '../../api';

const state = {
    isConnected: false,
    isSigning: false,
    publicKey: null,
    isInstalled: false,
};

const mutations = {

    SET_CONNECTED(state, isConnected) {
        state.isConnected = isConnected;
    },
    SET_SIGNING(state, isSigning) {
        state.isSigning = isSigning;
    },
    SET_WALLET_ADDR(state, publicKey) {
        state.publicKey = publicKey;
        this.commit('wallets/SET_WALLET_ADDR', publicKey, { root: true });
    },
    SET_INSTALLED(state, isInstalled) {
        state.isInstalled = isInstalled;
    },

};

const actions = {

    async init({ commit, dispatch }) {
        // console.log('Store - Init solflare dispatched');
        const isInstalled = await dispatch('checkIfInstalled');
        commit('SET_INSTALLED', isInstalled);
        if (isInstalled) {
            const provider = await dispatch('getProvider');
            provider.on('connect', (publicKey) => {
                console.log(`Solflare Event - connect + ${publicKey.toString()}`);
                commit('SET_CONNECTED', true);
                commit('SET_WALLET_ADDR', publicKey.toString());
            });
            provider.on('disconnect', () => {
                console.log(`Solflare Event - disconnect`);
                commit('SET_CONNECTED', false);
                commit('SET_WALLET_ADDR', null);
            });
            provider.on('accountChanged', (publicKey) => {
                if (publicKey) {
                    console.log(`Solflare Event - account changed to ${publicKey.toBase58()}`);
                    commit('SET_WALLET_ADDR', publicKey.toString());
                } else {
                    commit('SET_CONNECTED', false);
                    commit('SET_WALLET_ADDR', null);
                    console.error(`Solflare Event - account changed but no wallet address`);
                }
            });
        }
    },

    async connect({ commit, dispatch }, { justAdd = false, justConnect = false }) {

        const provider = await dispatch('getProvider');
        if (!provider) {
            throw new Error('Solflare wallet is not installed');
        }
        try {

            await provider.connect();

            if (provider.isConnected) {

                if(justConnect) {
                    dispatch('showNotification', { 
                        message: 'Solflare wallet connected successfully', 
                        type: 'success' 
                    }, { root: true });
               
                } else {

                    const walletType = 'sol';
                    const walletNetwork = 2; //'solana';
                    const walletProvider = 'solflare';

                    let methodInit = 'authWalletSignInit';
                    let methodVerify = 'authWalletSignVerify';
                    if(justAdd) {
                        methodInit = 'authWalletSetInit';
                        methodVerify = 'authWalletSetVerify';
                    }

                    const publicKey = provider.publicKey.toString();
                    commit('SET_WALLET_ADDR', publicKey);
                    commit('SET_CONNECTED', true);
                    commit('SET_SIGNING', true);

                    // Get the message to sign from the backend
                    const message = await dispatch(`${methodInit}`, {
                        walletAddress: publicKey,
                        walletType,
                        walletNetwork,
                        walletProvider
                    }, { root: true });

                    const signedMessage = await dispatch('signMessage', message);

                    // Authenticate with backend
                    await dispatch(`${methodVerify}`, {
                        walletAddress: publicKey,
                        signedMessage,
                        walletType,
                        walletNetwork,
                        walletProvider
                    }, { root: true });

                    commit('SET_SIGNING', false);

                    dispatch('showNotification', { 
                        message: 'Solflare wallet connected successfully', 
                        type: 'success' 
                    }, { root: true });
                }
            }
            
        } catch (err) {
            console.error('Solflare connection error:', err);
            dispatch('showNotification', { 
                message: `Failed to connect Solflare wallet: ${err.message}`, 
                type: 'error' 
            }, { root: true });
            throw err;
        }
    },

    async disconnect({ commit, dispatch }) {
        const provider = await dispatch('getProvider');
        if (provider && provider.isConnected) {
            await provider.disconnect();
            commit('SET_CONNECTED', false);
            commit('SET_WALLET_ADDR', null);
            dispatch('showNotification', { 
                message: 'Solflare wallet disconnected', 
                type: 'success' 
            }, { root: true });
        }
    },

    async signMessage({ dispatch }, message) {
        const provider = await dispatch('getProvider');
        if (!provider || !provider.isConnected) {
            throw new Error('Solflare wallet is not connected');
        }
        const encodedMessage = new TextEncoder().encode(message);
        const { signature } = await provider.signMessage(encodedMessage); // , 'utf8'
        const signedMessage = {
            signature: signature
        };
        return signedMessage;
    },

    async signTransaction({ dispatch }, { address, amount }) {
        const provider = await dispatch('getProvider');
        if (!provider || !provider.isConnected) {
            throw new Error('Solflare wallet is not connected');
        }
        // alert('signTransaction');
        try {
            const connection = new Connection(clusterApiUrl('mainnet-beta'), 'confirmed');
            
            const transaction = new Transaction().add(
                SystemProgram.transfer({
                    fromPubkey: new PublicKey(provider.publicKey.toString()),
                    toPubkey: new PublicKey(address),
                    lamports: amount
                })
            );

            transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
            transaction.feePayer = provider.publicKey;

            // Sign and send the transaction
            const signedTransaction = await provider.signTransaction(transaction);
            
            const signature = await connection.sendRawTransaction(signedTransaction.serialize());
            await connection.confirmTransaction(signature);
            
            return signature;
        } catch (error) {
            console.error('Solflare transaction error:', error);
            throw new Error(`Error sending transaction: ${error.message}`);
        }
    },


    ifSolflareInstalled() {
        return 'solflare' in window;
    },

    checkIfInstalled() {
        return new Promise((resolve) => {
            if (typeof window !== 'undefined') {
                resolve('solflare' in window);
            } else {
                resolve(false);
            }
        });
    },

    getProvider() {
        return new Promise((resolve) => {
            if (typeof window !== 'undefined') {
                resolve(window.solflare);
            } else {
                resolve(null);
            }
        });
    },

    // getSolflareProvider() {
    //     if ('solflare' in window) {
    //         return window.solflare;
    //     }
    //     return null;
    // },

    removeListeners({ dispatch }) {
        dispatch('getProvider').then(provider => {
            if (provider) {
                provider.off('connect');
                provider.off('disconnect');
                provider.off('accountChanged');
            }
        });
    },

    //* DeepLink to connect wallet on mobile *//

    async connectDeeplink({ commit, dispatch, rootState }, { mode = 'auth' }) {
        try {
            // Generate encryption key pair for Diffie-Hellman key exchange connection with Solflare
            const keyPair = nacl.box.keyPair();
            const dappPublicKey = keyPair.publicKey;
            const dappPrivateKey = keyPair.secretKey;

            const sessionToken = rootState.sessionToken;

            // Store keypair and session data in backend
            await api.post('auth/wallet/deeplink/prep', {
                data: {
                    wallet_type: 'solflare',
                    session: sessionToken,
                    dapp_public_key: bs58.encode(dappPublicKey),
                    dapp_private_key: bs58.encode(dappPrivateKey),
                    mode: mode
                }
            });

            // Define parameters according to Solflare docs
            const appUrl = encodeURIComponent(window.location.origin);
            const redirectLink = encodeURIComponent(`${window.location.origin}/auth/solflare?app_session=${sessionToken}`);
            const cluster = 'mainnet-beta';

            // Construct deeplink URL
            const deeplinkUrl = `https://solflare.com/ul/v1/connect?` +
                `app_url=${appUrl}&` +
                `dapp_encryption_public_key=${bs58.encode(dappPublicKey)}&` +
                `redirect_link=${redirectLink}&` +
                `cluster=${cluster}`;

            console.log('Opening deeplink URL:', deeplinkUrl);

            // Open Solflare app via deeplink
            window.location.href = deeplinkUrl;

        } catch (err) {
            console.error('Solflare deeplink connection error:', err);
            throw err;
        }
    },

    async handleConnectResponse({ commit, dispatch, rootState }, { solflare_encryption_public_key, data, nonce, session }) {
        try {
            console.log('Handling connect response with:', {
                solflare_encryption_public_key,
                data,
                nonce,
                session
            });

            // Update session token in the local storage and load its data to the app
            if(session!=rootState.sessionToken) {
                // alert('set settion to '+session);
                dispatch('loadSession', { sessionToken: session, sessionData: {} }, { root: true });
                // commit('SET_SESSION_TOKEN', sessionToken);
            }
            
            // Get stored keypair from backend
            const response = await api.post('auth/wallet/deeplink/load', {
                data: { session } // user session reveived from deeplink after redirect from solflare, 
                // effectively sessionToken of the previous session where the user was goin to deeplink
            });

            console.log('Backend response data:', response.data);

            if (!response.data?.data?.dapp_private_key) {
                throw new Error('No session data found. Please try connecting again.');
            }

            try {
                // Log input values before decoding
                console.log('Decoding inputs:', {
                    solflare_key: solflare_encryption_public_key,
                    dapp_private: response.data.data.dapp_private_key
                });

                const decodedSolflareKey = bs58.decode(solflare_encryption_public_key);
                const decodedPrivateKey = bs58.decode(response.data.data.dapp_private_key);

                // Verify key formats
                if (decodedSolflareKey.length !== 32) {
                    throw new Error(`Invalid solflare public key length: ${decodedSolflareKey.length}`);
                }
                if (decodedPrivateKey.length !== 32) {
                    throw new Error(`Invalid dapp private key length: ${decodedPrivateKey.length}`);
                }

                // Log the raw key values
                console.log('Decoded keys raw values:');
                console.log('solflareKey:', Array.from(decodedSolflareKey).join(', '));
                console.log('privateKey:', Array.from(decodedPrivateKey).join(', '));

                // Log data types and lengths
                console.log('Data type checks:', {
                    solflareKey: decodedSolflareKey instanceof Uint8Array,
                    privateKey: decodedPrivateKey instanceof Uint8Array,
                    data: bs58.decode(data) instanceof Uint8Array,
                    nonce: bs58.decode(nonce) instanceof Uint8Array
                });

                // alert('decodedSolflareKey='+decodedSolflareKey)
                // Generate shared secret with swapped order
                const sharedSecretDH = nacl.box.before(
                    decodedSolflareKey,     // Solflare's public key first
                    decodedPrivateKey      // Our private key second
                );

                console.log('Shared secret type:', sharedSecretDH instanceof Uint8Array);

                // Also let's log the raw decoded data and nonce
                console.log('Raw decoded data:', Array.from(bs58.decode(data)).join(', '));
                console.log('Raw decoded nonce:', Array.from(bs58.decode(nonce)).join(', '));

                // Log decryption inputs
                console.log('Decryption inputs:', {
                    encodedData: data,
                    encodedNonce: nonce,
                    decodedData: Array.from(bs58.decode(data)),
                    decodedNonce: Array.from(bs58.decode(nonce))
                });

                // Verify nonce length
                const decodedNonce = bs58.decode(nonce);
                if (decodedNonce.length !== 24) {
                    throw new Error(`Invalid nonce length: ${decodedNonce.length}`);
                }

                // Attempt decryption with explicit type conversions
                const decryptedData = nacl.box.open.after(
                    new Uint8Array(bs58.decode(data)),
                    new Uint8Array(bs58.decode(nonce)),
                    new Uint8Array(sharedSecretDH)
                );

                if (!decryptedData) {
                    throw new Error('Decryption returned null - invalid data or keys');
                }

                // Parse the decrypted data using TextDecoder instead of Buffer
                const decodedText = new TextDecoder().decode(decryptedData);
                console.log('Decoded text before parsing:', decodedText);
                
                const parsedData = JSON.parse(decodedText);
                console.log('Successfully decrypted and parsed data:', parsedData);

                // alert('parsedData='+JSON.stringify(parsedData));
                // parsedData contains only public_key and session

                const public_key = parsedData.public_key;
                const solflareSession = parsedData.session;

                // alert('public_key='+public_key+' ============== solflareSession='+solflareSession);
                // Store session data in backend
                await api.post('auth/wallet/deeplink/update', {
                    data: {
                        session,
                        public_key, // user wallet address
                        solflare_session: solflareSession,
                        shared_secret: bs58.encode(sharedSecretDH)
                    }
                });

                // Set wallet state
                commit('SET_CONNECTED', true);
                commit('SET_WALLET_ADDR', public_key);

                // Get message to sign from backend
                const mode = response.data.data.mode;

                const methodInit = mode === 'auth' ? 'authWalletSignInit' : 'authWalletSetInit'; // 'connect' for settings or payment
                // alert('methodInit='+methodInit);
                const message = await dispatch(methodInit, {
                    walletAddress: public_key,
                    walletType: 'sol',
                    walletNetwork: 2,
                    walletProvider: 'solflare'
                }, { root: true });

                // Sign the message
                await dispatch('signMessageDeeplink', { message, solflareSession, sharedSecretDH, session });

            } catch (decodeError) {

                console.error('Detailed decryption error:', {
                    name: decodeError.name,
                    message: decodeError.message,
                    stack: decodeError.stack
                });
                throw new Error(`Decryption failed: ${decodeError.message}`);
            }

        } catch (error) {
            console.error('Connection response error:', {
                name: error.name,
                message: error.message,
                stack: error.stack
            });
            throw error;
        }
    },

    async signMessageDeeplink({ dispatch, rootState }, { message, solflareSession, sharedSecretDH = null, session = null }) {
        try {
            const sessionToken = session || rootState.sessionToken;
            
            // Get shared secret and dapp key from backend
            const response = await api.post('auth/wallet/deeplink/load', {
                data: { session: sessionToken } 
            });

            if (response.data?.result !== 'success') {
                throw new Error(response.data?.data?.message || 'Failed to load wallet data');
            }

            if (!response.data.data.dapp_public_key) {
                throw new Error('Missing dapp public key');
            }
            
            // Get shared secret if not provided
            let sharedSecret;
            if (sharedSecretDH instanceof Uint8Array) {
                sharedSecret = sharedSecretDH;
            } else if (typeof sharedSecretDH === 'string') {
                sharedSecret = bs58.decode(sharedSecretDH);
            } else if (response.data.data.shared_secret) {
                sharedSecret = bs58.decode(response.data.data.shared_secret);
            } else {
                throw new Error('Missing shared secret');
            }

            // Create the message payload
            const payload = {
                message: bs58.encode(new TextEncoder().encode(message)),
                session: solflareSession,
                display: 'utf8'
            };

            // Use the helper function directly
            const [nonce, encryptedPayload] = encryptPayload(payload, sharedSecret);

            // Create redirect link without extra encoding
            const redirectLink = `${window.location.origin}/auth/solflare?app_session=${sessionToken}`;

            // URLSearchParams will handle the encoding
            const params = new URLSearchParams({
                dapp_encryption_public_key: response.data.data.dapp_public_key,
                nonce: bs58.encode(nonce),
                redirect_link: redirectLink,
                payload: bs58.encode(encryptedPayload)
            });

            const url = `https://solflare.com/ul/v1/signMessage?${params.toString()}`;
            window.location.href = url;

        } catch (error) {
            console.error('Solflare deeplink sign message error:', error);
            throw error;
        }
    },

    async handleSignMessageResponse({ dispatch, rootState }, { data, nonce, session }) {
        try {

            // Update session token in the local storage and load its data to the app
            if(session != rootState.sessionToken) {
                // alert('set session to ' + session);
                dispatch('loadSession', { sessionToken: session, sessionData: {} }, { root: true });
                // commit('SET_SESSION_TOKEN', sessionToken);
            }
            
            // Get stored keypair from backend
            const response = await api.post('auth/wallet/deeplink/load', {
                data: { session }
            });

            if(response.data?.result != 'success') {
                throw new Error('Error loading wallet data: ' + response.data?.message);
            }

            // Get data from saved session on backend
            const sharedSecret = bs58.decode(response.data.data?.shared_secret);
            const mode = response.data.data?.mode;
            const publicKey = response.data.data?.dapp_public_key;
            const wallet_address = response.data.data?.wallet_address;

            if (!sharedSecret || !mode || !publicKey) {
                throw new Error('Missing required data from backend');
            }

            // Decrypt the signature data using our helper function
            const signMessageData = decryptPayload(data, nonce, sharedSecret);
            
            // Log the decrypted data
            // alert('Decrypted signature data: ' + JSON.stringify(signMessageData, null, 2));

            // Extract signature from decrypted data
            const { signature } = signMessageData;
            if (!signature) {
                throw new Error('No signature found in decrypted data');
            }

            // Convert base58 signature string to Uint8Array
            const signatureBytes = bs58.decode(signature);

            // Format signature object to match what authWalletSignVerify expects
            const signedMessage = {
                signature: {  // Wrap in .signature property since authWalletSignVerify uses signedMessage.signature
                    type: "Buffer",
                    data: Array.from(signatureBytes)
                }
            };

            // Verify signature with backend based on mode
            const methodVerify = mode === 'auth' ? 'authWalletSignVerify' : 'authWalletSetVerify';

            await dispatch(methodVerify, {
                walletAddress: wallet_address,
                signedMessage,  // This will be processed by authWalletSignVerify to extract .signature
                walletType: 'sol',
                walletNetwork: 2,
                walletProvider: 'solflare'
            }, { root: true });

        } catch (error) {
            console.error('Error handling sign message response:', error);
            throw error;
        }
    },

    async signTransactionDeeplink({ dispatch, rootState }, { address, amount }) {
        // alert('sendTXDeeplink='+address+' '+amount);
    },

};

// Helper functions for encryption/decryption
const encryptPayload = (payload, sharedSecret) => {
    if (!sharedSecret) {
        throw new Error("missing shared secret");
    }
    const nonce = nacl.randomBytes(24);
    const encryptedPayload = nacl.box.after(
        new TextEncoder().encode(JSON.stringify(payload)),
        nonce,
        sharedSecret
    );
    return [nonce, encryptedPayload];
};

const decryptPayload = (data, nonce, sharedSecret) => {
    if (!sharedSecret) {
        throw new Error('missing shared secret');
    }

    const decryptedData = nacl.box.open.after(
        bs58.decode(data),
        bs58.decode(nonce),
        sharedSecret
    );

    if (!decryptedData) {
        throw new Error('Unable to decrypt data');
    }

    return JSON.parse(new TextDecoder().decode(decryptedData));
};

export default { namespaced: true, state, mutations, actions };