<template>
    <div v-if="showCookieConsent" 
         class="z-[707] max-w-[1200px] md:w-[80%] p-2 md:p-5 md:px-3 mx-auto fixed  
                max-md:bottom-[72px] md:bottom-12 left-3 right-5 text-center shadow-2xl 
                rounded-[10px] md:rounded-[18px] bg-opacity-80 backdrop-blur-sm
                text-blue-200 border border-slate-800 bg-slate-900">
        <div class="flex items-center gap-3 md:flex-wrap justify-between">
            <div class="flex md:flex-col flex-wrap gap-1 md:ml-2 items-start text-left">
                <span class="max-md:text-sm">This website uses cookies to provide better user experience.</span>
                <a href="/docs/privacy" target="_blank" class="text-blue-200 text-sm hover:text-blue-100 underline">Privacy Policy</a>
            </div>
            <button @click="acceptCookies" 
                    class=" b3 bl mr-2
                           transition-colors duration-200">
                Accept & Close
            </button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'Cookies',
    computed: {
        ...mapState(['cookieConsentNeeded', 'cookieConsentGiven']),
        showCookieConsent() {
            return this.cookieConsentNeeded && !this.cookieConsentGiven;
        }
    },
    methods: {
        acceptCookies() {
            this.$store.commit('SET_COOKIE_CONSENT_GIVEN', true);
        }
    },
    created() {
        // Check if consent was previously given
        if (localStorage.getItem('cookieConsent') === 'true') {
            this.$store.commit('SET_COOKIE_CONSENT_GIVEN', true);
        }
    }
}

</script>