<template>

    <Notify :notification="notification" DEBUG="DEBUG" />
    <Inform :information="information" DEBUG="DEBUG" /><!-- v-show="allowInform" :information="currentInformation" -->
    <Cookies v-if="!openWelcome" />

    <!--
    <TXModal :showTxSuccessModal="showTxSuccessModal" :showTxErrorModal="showTxErrorModal"></TXModal> 
    <botModal :startBotShow="startBotShow" DEBUG="DEBUG" />
    <profileModal v-show="openProfile" @hideProfile="hideProfile" DEBUG="DEBUG" />
    -->

    <AppLoader v-if="!appLoaded" />
    <AppError v-else-if="appError" />
    <div v-else class="min-w-[320px] text-base flex flex-col h-screen" :class="showLiner ? 'border-t border-slate-800 pt-8' : ''">

        <Transition name="liner-fade">
            <div v-if="showLiner" class="top-0 left-0 w-full h-8 border-b border-slate-700 bg-slate-950 fixed z-[700] flex flex-row justify-between items-center">
                <div class="flex-grow text-center font-mono text-xs font-normal text-slate-400">
                    $AIR Token &mdash; CA: 
                    <!-- launched on pump.fun -->
                    <b>...</b>
                </div>
                <div class="absolute right-1 top-1 cursor-pointer" @click="hideLiner"><img src="@/assets/set/close.svg" class="w-5 h-5"></div>
            </div>
        </Transition>

        <!--
        isAuth - if user is authorise, true if authorised
        openAuth - auth modal status, true if opened
        showAuth() - open auth modal
        hideAuth() - close auth modal
        -->
        <ModalAuth v-if="!isAuth" v-show="openAuth" @showConnect="showConnect" @hideAuth="hideAuth" @showAccess="showAccess" DEBUG="DEBUG" />
        
        <ModalConnectEmail v-if="isAuth" v-show="openConnectEmail" @hideModal="hideConnectEmailModal" DEBUG="DEBUG" />
        <ModalConnectTelegram v-if="isAuth" v-show="openConnectTelegram" @hideModal="hideConnectTelegramModal" DEBUG="DEBUG" />
        <ModalConnectWallet v-if="isAuth" v-show="openConnectWallet" @hideModal="hideConnectWalletModal" DEBUG="DEBUG" />
        
        <ModalAuthTelegram v-if="!isAuth && openAuthTelegram" DEBUG="DEBUG" />
        <ModalAuthPhantom v-if="!isAuth && openAuthPhantom" DEBUG="DEBUG" />
        <ModalAuthSolflare v-if="!isAuth && openAuthSolflare" DEBUG="DEBUG" />

        <ModalWallet v-if="openWallet" @hideWallet="hideWallet" DEBUG="DEBUG" />

        <AppHeader @showWelcome="showWelcome" @showAuth="showAuth" @showWallet="showWallet" :showLiner="showLiner" @clickLogo="clickLogo" />
        <AppMenu @showWelcome="showWelcome" @showAccess="showAccess" :showLiner="showLiner" @clickLogo="clickLogo" />
        <!-- v-if="isAuth"
        <AppMenuNoAuth v-else @showWelcome="showWelcome" @showAccess="showAccess" :showLiner="showLiner" /> -->

        <Welcome v-if="openWelcome" @hideWelcome="hideWelcome" />
        <Access v-if="openAccess" @hideAccess="hideAccess" />

        <div class="mt-12 md:mt-16 md:ml-14 mb-0 flex flex-col flex-grow">
            <!-- max-md:mb-16 ?? -->
            
            <!-- <div v-if="DEBUG" class="debug">
                isAuth = {{ isAuth }}
                userData = {{ userData }}
                isStandalone = {{ isStandalone }}<br>
                isIOS = {{ isIOS }}<br>
                isAndroid = {{ isAndroid }}<br>
                showInstallPrompt = {{ showInstallPrompt }}<br>
                showAndroidLoader = {{ showAndroidLoader }}<br>
                deferredPrompt = {{ deferredPrompt }}<br>
            </div> -->

            <!-- CLOSED ACCESS - REMOVE THIS:--><!-- v-if="isAuth || isDocs" -->
            <router-view v-if="isAuth || isDocs"
                @showWallet="showWallet"
                :windowWidth="windowWidth" 
                @showAuth="showAuth"
                :viewType="viewType" @changeViewType="changeViewType" @showAccess="showAccess"
                :DEBUG="DEBUG">
            </router-view>
            <!-- CLOSED ACCESS - REMOVE THIS:--><AppJoinToday v-else @showWelcome="showWelcome" @showAccess="showAccess" :showLiner="showLiner" />

        </div>
      
        <AppFooter />

    </div>

    <div v-if="isRouteLoading" class="fixed inset-0 bg-black/50 backdrop-blur-sm z-[9999] flex items-center justify-center">
        <div class="flex flex-col items-center gap-4">
            <div class="w-10 h-10 border-4 border-white rounded-full border-t-transparent animate-spin"></div>
            <p class="text-white text-base m-0">Loading...</p>
        </div>
    </div>

</template>
  
<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import Notify from '@/modals/Notify.vue';
import Inform from '@/modals/Inform.vue'; // ws events

import AppLoader from '@/components/AppLoader.vue';
import AppError from '@/components/AppError.vue';
import AppHeader from '@/components/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';
import AppMenu from '@/components/AppMenu.vue';
import AppMenuNoAuth from '@/components/AppMenuNoAuth.vue';
import AppJoinToday from '@/components/AppJoinToday.vue';
import Welcome from '@/modals/Welcome.vue';
import Access from '@/modals/Access.vue';

import ModalConnectWallet from '@/modals/ConnectWallet.vue';
import ModalConnectEmail from '@/modals/ConnectEmail.vue';
import ModalConnectTelegram from '@/modals/ConnectTelegram.vue';
import ModalAuth from '@/modals/Auth.vue';

import ModalAuthTelegram from '@/modals/Auth/Telegram.vue';
import ModalAuthPhantom from '@/modals/Auth/Phantom.vue';
import ModalAuthSolflare from '@/modals/Auth/Solflare.vue';

import ModalWallet from '@/modals/Wallet.vue';

import Cookies from '@/modals/Cookies.vue';

// import { Connection, PublicKey, Transaction, SystemProgram, clusterApiUrl } from '@solana/web3.js';
// import { Connection, PublicKey, LAMPORTS_PER_SOL, clusterApiUrl } from '@solana/web3.js';


//  import profileModal from '@/modals/Profile.vue';

//  import Bot from '@/modals/Bot.vue';
//  import TX from '@/modals/TX.vue';

 export default {
    name: 'Airpump',
    components: {
        Notify, Inform,
        AppLoader, AppError, 
        AppHeader, AppFooter,
        AppMenu, Welcome, Access,
        // Wallet
        ModalWallet,
        // Auth
        ModalAuth, AppMenuNoAuth, AppJoinToday,
        // Callback on auth
        ModalAuthTelegram, ModalAuthPhantom, ModalAuthSolflare,
        // Settings add to account
        ModalConnectEmail, ModalConnectTelegram, ModalConnectWallet,
        Cookies,
    },
    data() {
        return {

            DEBUG: false,
        
            // Data: null,
            // User: null,

            walletRaw: '-',

            viewType: 1, // global for al lists, depends on window width
            windowWidth: window.innerWidth,

            statsNet: '-',
            statsVol: '-',

            isLoading: false,
            isError: false,
            // isErrorText: null,
            // isErrorCode: null,

            isHome: null, // watch route
            isDocs: null, // watch route
            // allowInform: true,

            openWelcome: false, // set to true on prod
            // openAccess: false,
            // openTour: true,
            // openInfo: true,
            openConnect: false,
            openPofile: false,
            // openWallet: false,

            phantomInstalled: null,
            solflareInstalled: null,

            search: '',
            closeSearch: false,

            teleMiniApp: false,
            teleInit: null,
            teleInitUnsafe: null,
            teleData: {},

            isAlreadyWelcome: false,

            initWalletView: null,

            showLiner: false, // true,

            // miniAppHeight: 0,

            isRouteLoading: false,

        }
    },
    computed: {

        //* General App Data *//
        ...mapState(['notification', 'information']),
        ...mapState(['appData', 'appLoaded', 'appError', 'appErrorCode', 'appErrorText', 'isMobile']),

        //* User Auth *//
        ...mapState(['user', 'userData', 'userAccess', 'jwt', 'refreshToken']),
        isAuth() { return !!this.jwt; },

        //* WebSocket *//
        ...mapState('ws', ['infoQueue']),
        // ?? NOT USED ?? //
        currentInformation() {
            return this.infoQueue[0] || null;
        },

        ...mapState('ws', ['coinDataOrdersMessages', 'walletTransfersMessages']),

        ...mapGetters('modals', [
            'openAuth', 'openAccess', 'openWallet',
            'openConnectWallet', 'openConnectTelegram', 'openConnectEmail', 
            'openAuthTelegram', 'openAuthPhantom', 'openAuthSolflare',
        ]),

        // requireAuth() {
        //     const publicPages = ['/', '/home', '/about', '/terms', '/privacy', '/auth', '/exit'];
        //     return !publicPages.includes(this.$route.path);
        // },

        //* Wallets *//
        ...mapState('wallets', ['isConnecting', 'isConnected', 'walletNetwork', 'walletType', 'walletData', 'walletAddr']),
    
        // ...mapState({
        //     showWalletCreate: state => state.modals.walletCreate.show,
        //     showWalletDeposit: state => state.modals.walletDeposit.show,
        //     showWalletExport: state => state.modals.walletExport.show,
        //     showWalletBridge: state => state.modals.walletBridge.show,
        //     showCoinDeploy: state => state.modals.coinDeploy.show,
        //     showWallet: state => state.modals.wallet.show,
        //     showWalletBuy: state => state.modals.walletBuy.show,
        //     showWalletSell: state => state.modals.walletSell.show,
        // }),

    },
  
    async created() {

        // GET parameters
        await this.getParams();

        try {

            await this.initSession();
            await this.initWebSocket();

            await this.checkMobile();
            // this.isMobile = this.checkMobile(); // used for mobile connection - to use deeplink instead of interaction with provider
            // alert('isMobile: '+this.isMobile);

        } catch (error) {
            console.error('Error initializing app:', error);
            this.$store.commit('SET_APP_ERROR', { 
                code: 'Init', 
                text: error.message || 'Error connecting to server.' 
            });
            this.showNotification({
                type: 'error',
                message: error.message || 'Error connecting to server.'
            });
        } finally {
            this.$store.commit('SET_APP_LOAD', true);
        }

        /* Telegram Mini App ? */

        // if (window.Telegram?.WebApp && window.Telegram.WebApp.initData) { //

        //     alert('miniapp '+JSON.stringify(window.Telegram.WebApp));
        //     this.teleMiniApp = true;

        //     this.teleInit = window.Telegram.WebApp.initData;
        //     this.teleInitUnsafe = window.Telegram.WebApp.initDataUnsafe;

        //     Object.assign(this.teleData, {
        //         initData: window.Telegram.WebApp.initData,
        //         initDataUnsafe: window.Telegram.WebApp.initDataUnsafe, // contains query_id, user, auth_date, hash
        //         version: window.Telegram.WebApp.version,
        //         platform: window.Telegram.WebApp.platform,
        //         colorScheme: window.Telegram.WebApp.colorScheme,
        //         themeParams: window.Telegram.WebApp.themeParams,
        //         isExpanded: window.Telegram.WebApp.isExpanded,
        //         viewportHeight: window.Telegram.WebApp.viewportHeight,
        //         viewportStableHeight: window.Telegram.WebApp.viewportStableHeight,
        //         headerColor: window.Telegram.WebApp.headerColor,
        //         backgroundColor: window.Telegram.WebApp.backgroundColor,
        //         isClosingConfirmationEnabled: window.Telegram.WebApp.isClosingConfirmationEnabled,
        //     });

        //     try {
        //         await this.authTelegramWebapp(this.teleData);
        //     } catch (error) {
        //         console.error('Telegram webapp authentication failed:', error);
        //         this.showNotification({
        //             type: 'error',
        //             message: 'Telegram webapp authentication failed: ' + error.message,
        //         });
        //     }

        //     try {
        //         window.Telegram.WebApp.enableClosingConfirmation();
        //         window.Telegram.WebApp.disableVerticalSwipes();
        //         window.Telegram.WebApp.expand(); // Telegram.WebApp.expand();
        //     } catch (error) {
        //         // console.error('Error expanding Telegram WebApp:', error);
        //     }

        //     // this.setMiniAppHeight();
        //     // window.addEventListener('resize', this.setMiniAppHeight);

        // } else {
        //     this.teleMiniApp = false;
        //     // await this.authWebpage();
        // }

        // console.log('Step 2 - Init Wallets');
        await this.initMetamask();
        await this.initPhantom();
        await this.initSolflare();
        this.checkConnection(); 

        // // Check/initialize session
        // try {
        //     await this.initSession();
        //     // Session is now initialized or loaded
        // } catch (error) {
        //     console.error('Failed to initialize/load session:', error)
        //     // Error handling is now done in the store action
        // }
        
        // Remove the route watcher and add navigation guards
        this.$router.beforeEach((to, from, next) => {
            if (from.name) { // Only show loader if coming from another route
                this.isRouteLoading = true;
            }
            next();
        });

        this.$router.afterEach(() => {
            // Wait for component to be loaded and mounted
            this.$nextTick(() => {
                setTimeout(() => {
                    this.isRouteLoading = false;
                }, 100); // Small delay to ensure component is mounted
            });
        });

    },
    mounted() {

        if(process.env.VUE_APP_DEBUG==1) this.DEBUG=true;

        document.title = 'Airpump';

        // window.addEventListener('resize', this.updateWidth);

        // // Solana Wallets //

        // // Double Check
        // this.phantomInstalled = this.checkPhantomInstalled();
        // this.solflareInstalled = this.checkSolflareInstalled();

        // this.initWalletListeners();
        // this.checkWalletConnection(); // ?? !!

        // window.addEventListener('message', this.handleMessage); // twitter login

        // Add resize event listener
        window.addEventListener('resize', this.updateWidth);


    },
    methods: {

        ...mapActions('ws', ['initWebSocket']),

        ...mapActions(['checkMobile']),

        ...mapActions(['initSession', 'setGetParams']),
        ...mapActions(['showNotification']),

        ...mapActions('modals', [
            'showAuthModal', 'hideAuthModal', 
            'showAccessModal', 'hideAccessModal',
            'showWalletModal', 'hideWalletModal',
            'showConnectEmailModal', 'hideConnectEmailModal',
            'showConnectTelegramModal', 'hideConnectTelegramModal',
            'showConnectWalletModal', 'hideConnectWalletModal',
        ]),

        // ...mapActions(['authTelegramWebapp']),

        hideNotify() {
            this.$store.dispatch('hideNotification');
        },

        hideLiner() {
            this.showLiner = false;
        },

        //* Wallets *//
        ...mapActions('wallets', ['checkConnection', 'removeAllListeners']), // not used: , 'connectPhantom', 'connectSolflare', 'disconnectWallet', 'signMessage', 
        ...mapActions('metamask', {initMetamask: 'init', switchChainMetamask: 'switchChain' }),
        ...mapActions('phantom', {initPhantom: 'init' }),
        ...mapActions('solflare', {initSolflare: 'init' }),
        // ...mapActions('adapter', {initSolflare: 'init' }),

        ...mapActions('ws', ['subscribeToPrivateNotifications', 'subscribeToPrivateOrders', 'subscribeToPrivateTransfers', 'subscribeToRecentCoins']),

        // Double Checkers
        // checkSolflareInstalled() {
        //     return 'solflare' in window;
        // },
        // checkPhantomInstalled() {
        //     // const isPhantomInstalled = window.phantom?.solana?.isPhantom
        //     // if not - https://phantom.app/
        //     // return 'solana' in window && window.solana.isPhantom;
        //     return 'phantom' in window || 'solana' in window;
        // },

        // old

        // ...mapActions(['connectTONWallet', 'connectSolanaWallet', 'connectEVMWallet']),
        // connectTON() {
        //     this.connectTONWallet();
        // },
        // connectSolana() {
        //     this.connectSolanaWallet();
        // },
        // connectEVM() {
        //     this.connectEVMWallet();
        // },

        // ...mapActions(['setCurrentNetwork']),
        // changeNetwork() {
        //     this.setCurrentNetwork(this.selectedNetwork);
        // },

        //* Save GET Params *//

        async getParams() {
            const urlParams = new URLSearchParams(window.location.search);
            const inviteCode = urlParams.get('i') || null;
            const utmSource = urlParams.get('utm_source') || urlParams.get('from') || '';
            const utmCampaign = urlParams.get('utm_campaign') || '';
            const utmMedium = urlParams.get('utm_medium') || '';
            console.log('GET' +JSON.stringify({ utmSource, utmCampaign, utmMedium, inviteCode }));
            await this.setGetParams({ utmSource, utmCampaign, utmMedium, inviteCode });
        },

        //* Utilities *//
    
        scrollStop() { document.body.classList.add('no-scroll'); },
        scrollStart() { document.body.classList.remove('no-scroll'); },

        isActive(name) { return this.$route.name === name; },

        checkRoute(route) {
            if(route.name===undefined) {
                return;
            }
            console.log('Current route:', route.name);
            this.isHome = route.name === 'dash'; // not 'list'!
            this.isDocs = route?.name === 'docs' || route?.name?.startsWith('docs_');
            // DO THIS ON PAGE LOAD AFTER WE HAVE THE ROUTE DEFINED //
            if(!this.isAuth && !this.isDocs && !this.isAlreadyWelcome &&
            !this.openAuthTelegram && !this.openAuthPhantom && !this.openAuthSolflare ) {
                this.showWelcome();
                this.isAlreadyWelcome = true;
            }
            if(!this.isHome) {
                // this.hideTour();
                // this.hideInfo();
            }
            // if(route.name === 'launch' || route.name === 'start') {
            //     this.allowInform = false;
            // } else {
            //     this.allowInform = true;
            // }
        },

        //* Modals *//

        // showWallet(initView) {
        //     this.openWallet = true;
        //     this.initWalletView = initView;
        // },
        // hideWallet() {
        //     this.openWallet = false;
        // },

        showWelcome() {
            this.openWelcome = true;
        },
        hideWelcome() {
            this.openWelcome = false;
        },
        
        clickLogo() {
            if(this.$route.name === 'dash') {
                this.showWelcome();
            } else {
                this.$router.push('/');
            }
        },

        showWallet(initView) { this.showWalletModal(initView); }, hideWallet() { this.hideWalletModal(); },

        showAccess() { this.showAccessModal(); }, hideAccess() { this.hideAccessModal(); },
        showAuth() { this.showAuthModal(); }, hideAuth() { this.hideAuthModal(); },
        
        // showConnect() {
        //     this.openConnect = !this.openConnect;
        //     this.openSign = false;
        // },
        // hideConnect() {
        //     this.openConnect = false;
        // },
        // showAuth() {
        //     this.openSign = !this.openSign;
        //     this.openConnect = false;
        // },
        // hideAuth() {
        //     this.openSign = false;
        // },
        // hideProfile() {
        //     this.openProfile = false;
        // },
        // hideTour() {
        //     this.openTour = false;
        // },
        // hideInfo() {
        //     this.openInfo = false;
        // },

        // closeBotShow() {
        //     this.$store.commit('SET_START_BOT_SHOW', false);
        // },

        // doMenu () { this.openMenu = !this.openMenu; },
        // doSignUp () { this.openSignUp = !this.openSignUp; },
        // doSignIn () { this.openSignIn = !this.openSignIn; },
        // doProfile () { this.openProfile = !this.openProfile; },

        // Twitter Login //

        // async twitterLogin() {
        //     try {
        //         const response = await this.$api.post('auth/twitter_login', {
        //         session: this.$store.state.sessionToken
        //         });
                
        //         if (response.data.status === 'success') {
        //         // Open the Twitter auth URL in a new window
        //         window.open(response.data.auth_url, 'Twitter Login', 'width=600,height=600');
        //         }
        //     } catch (error) {
        //         console.error('Error initiating Twitter login:', error);
        //     }
        // },

        // handleMessage(event) {
        //     if (event.data === 'twitter_login_success') {
        //         alert('twitter_login_success');
        //         // Refresh the page or update the app state as needed
        //         this.$router.go(0);
        //     }
        // },

        // STANDALONE

        // checkStandaloneMode() {
        // this.isStandalone = window.matchMedia('(display-mode: standalone)').matches ||
        //                     window.navigator.standalone ||
        //                     document.referrer.includes('android-app://');
        // },

        // detectDevice() {
        //     const userAgent = window.navigator.userAgent.toLowerCase();
        //     this.isIOS = /iphone|ipad|ipod/.test(userAgent);
        //     this.isAndroid = /android/.test(userAgent);
        // },
        // listenForInstallPrompt() {
        //     window.addEventListener('beforeinstallprompt', (e) => {
        //         e.preventDefault();
        //         this.deferredPrompt = e;
        //         this.showInstallPrompt = true;
        //     });
        // },
        // showAndroidInstallLoader() {
        //     this.showAndroidLoader = true;
        //     setTimeout(() => {
        //         this.doneAndroidLoader = true;
        //         // this.triggerAndroidInstall();
        //     }, 5000);
        // },
        // doAndroidInstall() {
        //     if (this.deferredPrompt) {
        //         this.deferredPrompt.prompt();
        //         this.deferredPrompt.userChoice.then((choiceResult) => {
        //         if (choiceResult.outcome === 'accepted') {
        //             console.log('User accepted the install prompt');
        //         } else {
        //             console.log('User dismissed the install prompt');
        //         }
        //         this.deferredPrompt = null;
        //         });
        //     }
        //     this.showAndroidLoader = false;
        // },

        //* Telegram Mini App Tweaks *//

        // setMiniAppHeight() {
        //     if (this.teleMiniApp && window.Telegram?.WebApp?.viewportHeight) {
        //             this.miniAppHeight = window.Telegram.WebApp.viewportHeight;
        //     }
        // },

        // View //

        changeViewType(viewType) {
            if(this.windowWidth < 695 && viewType === 1) viewType = 2; // mdm
            // if(this.windowWidth < 420 && viewType === 1) viewType = 2; // ms
            this.viewType = viewType;
        },

        updateWidth() {
            this.windowWidth = window.innerWidth;
            if(this.windowWidth < 695 && this.viewType === 1) {
                this.viewType = 2; // mdm
            }
        },

        doDEBUG() { this.DEBUG = !this.DEBUG; },
    },
    watch: {
        // walletAddr(newVal, oldVal) {
        //     console.log('Wallet address changed:', newVal);
        //     this.walletRaw = newVal;
        // },
        $route: {
            immediate: true,
            handler(to, from) {
                this.checkRoute(to);
            }
        },
        openWelcome(newValue) {
            if (newValue) this.scrollStop(); else this.scrollStart();
        },
        openAccess(newValue) {
            if (newValue) this.scrollStop(); else this.scrollStart();
        },
        openWallet(newValue) {
            if (newValue) this.scrollStop(); else this.scrollStart();
        },
        openAuth(newValue) {
            if (newValue) this.scrollStop(); else this.scrollStart();
        },


        coinDataOrdersMessages: {
            handler(messages) {
                // Handle any order update
                if (messages && Object.keys(messages).length > 0) {
                    const latestUpdate = Object.values(messages)[0];
                    if (latestUpdate && latestUpdate.orders && latestUpdate.orders.length > 0) {
                        const order = latestUpdate.orders[0];
                        if (order.order_status === 2) {
                            this.showNotification({
                                type: 'success',
                                message: `${order?.order_type === 1 ? 'Buy' : 'Sell'} order for ${order?.order_amount} of ${order?.coin?.coin_ticker} successfully completed`
                            });
                        } else if (order.order_status === 3) {
                            this.showNotification({
                                type: 'error',
                                message: `${order?.order_type === 1 ? 'Buy' : 'Sell'} order for ${order?.order_amount} of ${order?.coin?.coin_ticker} failed`
                            });
                        }
                    }
                }
            },
            deep: true
        },

        walletTransfersMessages: {
            handler(messages) {
                // Handle any transfer update
                if (messages && Object.keys(messages).length > 0) {
                   
                    const latestUpdate = Object.values(messages)[0];
                    if (latestUpdate && latestUpdate.transfers && latestUpdate.transfers.length > 0) {
                        const transfer = latestUpdate.transfers[0];
                        let notificationType = 'info';
                        let notificationText = '';
                        switch(transfer.transfer_status) {
                            case 1: // processing
                                notificationType = 'info';
                                notificationText = `Processing ${transfer.transfer_type === 1 ? 'transfer' : 'deposit'} of ${transfer.transfer_amount} SOL`;
                                break;
                            case 2: // completed
                                notificationType = 'success';
                                notificationText = `${transfer.transfer_type === 1 ? 'Transfer' : 'Deposit'} of ${transfer.transfer_amount} SOL completed`;
                                break;
                            case 3: // failed
                                notificationType = 'error';
                                notificationText = `${transfer.transfer_type === 1 ? 'Transfer' : 'Deposit'} of ${transfer.transfer_amount} SOL failed`;
                                break;
                        }
                        
                        this.showNotification({
                            type: notificationType,
                            message: notificationText
                        });
                    }
                }
            },
            deep: true
        },

        userData (newValue) {
            if(newValue) {
                this.subscribeToPrivateTransfers({ wsCode: newValue.ws_code }); 
                this.subscribeToPrivateOrders({ wsCode: newValue.ws_code }); 
                this.subscribeToRecentCoins({ wsCode: newValue.ws_code });
                this.subscribeToPrivateNotifications({ wsCode: newValue.ws_code });
            }
        },
        
        windowWidth: {
            handler(newWidth) {
                if (newWidth < 520 && this.viewType === 1) {
                    this.viewType = 2;
                }
            },
            immediate: true
        },
        
        openAuthTelegram(newValue) { if (newValue) this.scrollStop(); else this.scrollStart(); },
        openAuthPhantom(newValue) { if (newValue) this.scrollStop(); else this.scrollStart(); },
        openAuthSolflare(newValue) { if (newValue) this.scrollStop(); else this.scrollStart(); },
        
        // 'modals.AuthTelegram.code'(newValue) {
        //     console.log('Telegram auth code changed:', newValue);
        // }
        // '$route.name': {
        //     immediate: true,
        //     handler(newName) {
        //         console.log('Route name changed:', newName);
        //     }
        // }
    },
    beforeUnmount() {
        // Remove event listeners for wallets
        // this.removeAllListeners();
        // if (this.provider) {
        //     this.provider.off('disconnect', this.handleDisconnect);
        //     this.provider.off('accountChanged', this.handleAccountChanged);
        // }
        // if (this.isPhantomInstalled()) {
        //     window.solana.removeAllListeners('connect');
        //     window.solana.removeAllListeners('disconnect');
        //     window.solana.removeAllListeners('accountChanged');
        //     window.solana.removeAllListeners('networkChanged');
        // }
        // if (this.isSolflareInstalled()) {
        //     window.solflare.off('connect');
        //     window.solflare.off('disconnect');
        //     window.solflare.off('accountChanged');
        // }

        // Remove resize event listener
        window.removeEventListener('resize', this.updateWidth);
    },
    beforeDestroy() {
        this.scrollStop();
        // if (this.TONConnection) {
        //     this.TONConnection.onStatusChange(null);
        // }
        // Twitter Auth
        // window.removeEventListener('message', this.handleMessage); // twitter auth
        // window.removeEventListener('message', this.handleTelegramAuth);
        // window.removeEventListener('resize', this.updateWidth); // viewType=1
    },
    deactivated() {
        this.scrollStop();
    },

}
</script>

<style>
.liner-fade-enter-active,
.liner-fade-leave-active {
    transition: opacity 0.3s ease;
}

.liner-fade-enter-from,
.liner-fade-leave-to {
    opacity: 0;
}

/* .miniapp-height {
    min-height: v-bind('miniAppHeight + "px"');
    background-color: #099;
} */
</style>