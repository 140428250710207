<template>
    <div @click="closeModal()" class="fixed inset-0 bg-black backdrop-blur-sm bg-opacity-45 z-[800] flex flex-col h-full py-8 overflow-y-auto">
        <div @click.stop class="bg-slate-800 md:p-6 p-4 shadow-xl shadow-slate-950
            rounded-sm border border-white border-opacity-10
            min-w-[320px] w-[80%] max-w-[500px] mx-auto my-auto">
            
            <div class="flex flex-row justify-between">
                <h2 class="font-bold mb-3">Solflare Wallet Connection</h2>
                <div @click="closeModal()" class="p2 cursor-pointer">
                    <img src="@/assets/set/close.svg" class="w-5 h-5">
                </div>
            </div>

            <div v-if="isLoading" class="flex flex-col justify-center gap-3 border border-slate-600 p-5 rounded-xl mb-4 text-center min-h-[250px]">
                <div class="spinner-32 mx-auto"></div>
                <h3 class="">Verifying authentication...</h3>
            </div>
            <div v-else>
                <div class="mb-5 text-lg">
                    {{ status }}
                </div>

                <div v-if="error" class="flex flex-col gap-3 border border-red-500 border-opacity-50 p-5 rounded-xl mb-4 bg-red-500 bg-opacity-10">
                    <h3 class="font-bold text-red-400">Error</h3>
                    <div class="text-red-300">{{ error }}</div>
                </div>

                <div class="flex justify-end">
                    <button @click="closeModal()" class="b3 bc">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import api from '@/api';

export default {
    data() {
        return {
        isLoading: false,
        status: 'Processing response from Solflare...', // Please wait while we confirm your Solflare wallet connection.
        error: null
        };
    },
    // computed: {
    //     // ...mapState('modals', ['openAuthPhantom']),
    //     // showModal() {
    //     //     return this.openAuthPhantom;
    //     // },
    // },
    mounted() {
        this.processResponse();
    },
    methods: {

        ...mapActions(['showNotification']),
        
        ...mapActions('modals', ['hideAuthSolflareModal']),

        ...mapActions('solflare', ['handleConnectResponse', 'handleSignMessageResponse']),
        
        closeModal() {
            this.hideAuthSolflareModal();
        },

        async processResponse() {
            try {
                // Get URL parameters
                const urlParams = new URLSearchParams(window.location.search);
                // alert('urlParams: ' + JSON.stringify(urlParams));
                
                // Check for error response
                const errorCode = urlParams.get('errorCode');
                const errorMessage = urlParams.get('errorMessage');
                if (errorCode || errorMessage) {
                    throw new Error(errorMessage || 'Unknown error from Solflare');
                }

                // Check for required parameters
                const data = urlParams.get('data');
                const nonce = urlParams.get('nonce');
                const session = urlParams.get('app_session');

                if (!data || !nonce || !session) {
                    console.error('Missing parameters:', { data, nonce, session });
                    throw new Error('Missing required parameters');
                }

                // Handle connect response
                if (urlParams.get('solflare_encryption_public_key')) {
                    this.status = 'Processing connection response...';
                    await this.handleConnectResponse({
                        solflare_encryption_public_key: urlParams.get('solflare_encryption_public_key'),
                        data,
                        nonce,
                        session // is app_session not wallet session
                    });
                } 
                // Handle sign message response
                else {
                    alert('Solflare to handleSignMessageResponse' + JSON.stringify(data));
                    this.status = 'Processing signature response...';
                    await this.handleSignMessageResponse({
                        data,
                        nonce,
                        session // is app_session not wallet session
                    });

                    // Redirect back to the home page - that is Dash?
                    this.$router.push({ name: 'dash' });
                }

                // Redirect back to the original page
                // this.status = 'Success! Redirecting...';
                // setTimeout(() => {
                //     window.location.href = '/';
                // }, 1000);
                this.hideAuthSolflareModal();

            } catch (error) {
                console.error('Error processing Solflare response:', error);
                this.status = `Error: ${error.message}`;
                this.showNotification({
                    message: 'Error processing Solflare response: ' + error.message,
                    type: 'error'
                });
            }
        }
    }

};
</script>
    